import { CosmosCustomer } from "../customer";
import { PortalOrderProduct } from "../order-creation/portal-order-product";
import { ProductWithInventoryAndQuantity } from "../order-creation/product-with-quantity";

export class PricelistSingleRequest {
    companyId: string;
    currency: string;
    custvendAc: string;
    priceDiscModule: 0 | 1;
    qty: number;
    unitOfMeasure: string;
    itemId: string;

    constructor(product: PortalOrderProduct | ProductWithInventoryAndQuantity, customer: CosmosCustomer) {
        if (!product || !customer) {
            return;
        }
        this.companyId = customer.TikkurilaCompanyRegisterCode;
        this.currency = customer.CurrencyCode?.toLowerCase();
        this.custvendAc = customer.AXCode;
        this.priceDiscModule = 0;
        if ((product as PortalOrderProduct).axCode) {
            const portalProduct = product as PortalOrderProduct;
            this.itemId = portalProduct.axCode;
            this.unitOfMeasure = portalProduct.masterBasicUMCode;
            this.qty = portalProduct.quantity;
        } else if ((product as ProductWithInventoryAndQuantity).AXCode) {
            const productWIQ = product as ProductWithInventoryAndQuantity;
            this.itemId = productWIQ.AXCode;
            this.unitOfMeasure = productWIQ.MasterBasicUMCode;
            this.qty = productWIQ.Quantity;
        }
    }
}
