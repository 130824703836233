import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { IPortalOrderService } from 'src/app/portal-order/services/portal-orders.service.interface';
import { placedOrdersDetailsFormName, placedOrdersDetailsLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosAddress, NewOrderAddress } from 'src/app/shared/models/address/address';
import { CrmContact } from 'src/app/shared/models/contact';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { OrderHeader } from 'src/app/shared/models/orders/order-header';
import { OrderLine } from 'src/app/shared/models/orders/order-line';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { IAddressService } from 'src/app/shared/services/address/address.service.interface';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { IPrintService } from 'src/app/shared/services/print/print.service.interface';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { CurrentUserContactStore } from 'src/app/shared/stores/current-user-contact.store';
import { Utils } from 'src/app/shared/utils';
import { IOrderService } from '../services/order.service.interface';
import { orderLineColumns } from './order-line-columns';

@Component({
  selector: 'ntw-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss', './../../shared/styles/button.scss']
})
export class OrderDetailComponent extends KendoGridBase<OrderLine> implements OnInit {
  selectedOrderHeaderId: string;
  selectedOrderHeader: OrderHeader;
  portalOrder: PortalOrder;
  formConfig: FormConfiguration;
  columnsFormConfig: FormConfiguration;
  columnSettings: ColumnSettings[];
  isSubmittingPrintPackingSlipRequest = false;
  isSubmittingPrintInvoiceRequest = false;

  currentAccount: CrmAccount;
  currentContact?: CrmContact;
  currentCustomer: CosmosCustomer;

  deliveryAddress: NewOrderAddress = null;
  invoiceAddress: NewOrderAddress = null;

  public firstRow: TitleValue[] = [];
  public secondRow: TitleValue[] = [];
  public thirdRow: TitleValue[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private addressService: IAddressService,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private currentUserContactStore: CurrentUserContactStore,
    private customerService: ICustomerService,
    private kendoAlertService: KendoAlertService,
    private orderService: IOrderService,
    private portalOrderService: IPortalOrderService,
    private printService: IPrintService,
    private router: Router,
    private securityService: SecurityService,
  ) {
    super();
  }

  ngOnInit() {
    this.companyAdminConfigurationStore.getFormConfiguration(placedOrdersDetailsFormName).pipe(concatMap(placedOrdersFormConfig => {
      this.formConfig = placedOrdersFormConfig;
      return this.companyAdminConfigurationStore.getFormConfiguration(placedOrdersDetailsLineColumnsFormName);
    }), concatMap(columnsFormConfig => {
      this.columnsFormConfig = columnsFormConfig;
      return this.securityService.getCurrentAccount();
    }), concatMap(currentAccount => {
      this.currentAccount = currentAccount;
      return this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode);
    }), concatMap(currentCustomer => {
      this.currentCustomer = currentCustomer;
      return this.activatedRoute.paramMap;
    })).subscribe({
      next: params => {
        this.selectedOrderHeaderId = params.get("id");
        this.columnSettings = FormConfiguration.applyToColumnSettings(this.columnsFormConfig, [...orderLineColumns]);
        this.getEntitiesForAccount();
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
    this.currentUserContactStore.get().subscribe(contact => this.currentContact = contact);
  }

  getEntitiesForAccount() {
    this.orderService.getOrderDetail(this.selectedOrderHeaderId, this.currentAccount.ntw_axcode).subscribe({
      next: retrievedOrderheader => {
        if (!retrievedOrderheader) {
          return;
        }

        this.selectedOrderHeader = retrievedOrderheader;
        this.entities = retrievedOrderheader.OrderLines;
        this.generateGridDataView();
        this.fillRowsWithData();
        this.getAddresses(this.selectedOrderHeader);

        this.portalOrderService.GetPortalOrderDetailsById(this.selectedOrderHeader.bkCustomerDeliveryKey, this.selectedOrderHeader.DaxIntegration.toLowerCase()).subscribe({
          next: portalOrder => {
            this.portalOrder = portalOrder;
          },
          error: error => {
            this.kendoAlertService.showErrorAlert(error);
          }
        });
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.loading = false;
      }
    });
  }

  public async getAddresses(order: OrderHeader) {
    if (order.DeliveryPostalAddressRecId) {
      this.addressService.getAddressById(order.DeliveryPostalAddressRecId).subscribe({
        next: address => {
          this.deliveryAddress = this.mapToNewOrderAddress(address);
          this.fillRowsWithData();
        },
        error: error => {
          this.kendoAlertService.showErrorAlert(error);
          this.loading = false;
        }
      });
    }

    const handleError = (error) => {
      this.kendoAlertService.showErrorAlert(error);
      this.loading = false;
      return undefined;
    }

    if (order.bkInvoicePostalAddressKey || order.bkInvoiceAccountKey) {
      let address: CosmosAddress = null;

      if (order.bkInvoicePostalAddressKey) {
        address = await firstValueFrom(this.addressService.getAddressById(order.bkInvoicePostalAddressKey)).catch(error => handleError(error));
      }
      if (!address && order.bkInvoiceAccountKey) {
        address = await firstValueFrom(this.addressService.getInvoiceAddressByCustomerKey(order.bkInvoiceAccountKey)).catch(error => handleError(error));
      }
      if (!address && order.bkInvoiceAccountKey) {
        address = await firstValueFrom(this.addressService.getPrimaryAddressByCustomerKey(order.bkInvoiceAccountKey)).catch(error => handleError(error));
      }

      if (address) {
        this.invoiceAddress = this.mapToNewOrderAddress(address);
        this.fillRowsWithData();
      }
    }
  }

  public fillRowsWithData() {
    this.firstRow = [];
    this.secondRow = [];
    this.thirdRow = [];

    const firstRow = [
      {
        field: "Id",
        title: "order.Id",
        value: this.selectedOrderHeader.bkSalesOrderHeader,
        subtitle: ''
      },
      {
        field: "bkOrderDateKey",
        title: "order.bkOrderDateKey",
        value: Utils.FormatDate(this.selectedOrderHeader.bkOrderDateKey),
        subtitle: ''
      },
      {
        field: "bkRequestedShipmentDateKey",
        title: "order.bkRequestedShipmentDateKey",
        value: Utils.FormatDate(this.selectedOrderHeader.bkRequestedShipmentDateKey),
        subtitle: ''
      },
      {
        field: "Status",
        title: "order.Status",
        value: this.selectedOrderHeader.SalesStatus,
        subtitle: ''
      }
    ];

    const secondRow = [
      {
        field: "TotalLines",
        title: "order.TotalLines",
        value: Utils.FormatNumber(this.selectedOrderHeader.TotalLines),
        subtitle: ''
      },
      {
        field: "TotalAmount",
        title: "order.TotalAmount",
        value: Utils.FormatNumber(this.selectedOrderHeader.TotalAmount, 2),
        subtitle: ''
      },
      {
        field: "TotalVat",
        title: "order.TotalVat",
        value: Utils.FormatNumber(this.selectedOrderHeader.TotalVat, 2),
        subtitle: ''
      },
      {
        field: "TotalAmountIncludingVat",
        title: "order.TotalAmountIncludingVat",
        value: Utils.FormatNumber(this.selectedOrderHeader.TotalAmountIncludingVat, 2),
        subtitle: ''
      }
    ];

    const thirdRow = [
      {
        field: "CustomerRequisition",
        title: "order.CustomerRequisition",
        value: this.selectedOrderHeader.CustomerRequisition,
        subtitle: ''
      },
      {
        field: "CustomerReference",
        title: "order.CustomerReference",
        value: this.selectedOrderHeader.CustomerReference,
        subtitle: ''
      },
      {
        field: "deliveryAddress",
        title: "order.deliveryAddress",
        value: this.addressToString(this.deliveryAddress),
        subtitle: ''
      },
      {
        field: "invoiceAddress",
        title: "order.invoiceAddress",
        value: this.addressToString(this.invoiceAddress),
        subtitle: ''
      }
    ];

    this.firstRow = FormConfiguration.applyToTitleValues(this.formConfig, firstRow);
    this.secondRow = FormConfiguration.applyToTitleValues(this.formConfig, secondRow);
    this.thirdRow = FormConfiguration.applyToTitleValues(this.formConfig, thirdRow);
  }

  public addressToString(address: NewOrderAddress): string {
    if (!address) {
      return "";
    }
    const street = address?.street?.split("\\")[0];

    const addressString = street + "\n"
      + address.zipCode + " " + address.city;
    return addressString;
  }

  openOriginalOrder() {
    if (this.portalOrder) {
      this.router.navigate(['account', this.currentAccount.accountid, 'openorders', this.portalOrder.portalOrderId ?? this.portalOrder.id], { state: { portalOrder: this.portalOrder } });
    }
  }

  orderNotFromCustomerPortal() {
    return this.selectedOrderHeader.SalesOriginId !== "CustPortal" || this.selectedOrderHeader.DaxIntegration == undefined;
  }

  canCreateSendPackingSlipRequest() {
    return this.selectedOrderHeader
      && this.selectedOrderHeader.bkCompanyKey
      && this.selectedOrderHeader.bkSalesOrderHeader
      && this.selectedOrderHeader.bkCustomerDeliveryKey
      && this.currentContact
      && this.currentContact.emailaddress1;
  }

  sendPackingSlip() {
    if (!this.canCreateSendPackingSlipRequest() || this.isSubmittingPrintPackingSlipRequest) {
      return;
    }

    this.isSubmittingPrintPackingSlipRequest = true;

    this.printService.printPackingSlip(this.selectedOrderHeader, this.currentContact.emailaddress1)
      .subscribe({
        next: response => {
          if (!response.isOk) {
            this.kendoAlertService.showErrorAlert(response.errorMsg);
          }
          else {
            this.kendoAlertService.showSuccessAlert("alerts.printPackingSlipRequestSubmittedSuccessfully", true);
          }
          this.isSubmittingPrintPackingSlipRequest = false;
        },
        error: error => {
          this.kendoAlertService.showErrorAlert(error);
          this.isSubmittingPrintPackingSlipRequest = false;
        }
      });
  }

  mapToNewOrderAddress(address: CosmosAddress): NewOrderAddress {
    if (!address) {
      return null;
    }

    return {
      recId: address.recId,
      countryRegion: address.countryRegion,
      city: address.city,
      zipCode: address.zipCode,
      street: address.street,
      county: address.county,
      isWriteIn: address.isWriteIn,
      name: address.name,
      isChecked: address.isChecked
    }
  }
}
