import { ColorShade } from "../colorshade/colorshade-value";
import { PriceListResponseItem } from "../pricelists/pricelist-response";
import { ProductWithInventoryAndQuantity } from "./product-with-quantity";

export class PortalOrderProduct {
    orderProductNumber: number;
    lineAmount: number;
    unitNetPrice: number;
    quantity: number;
    bkProductKey: string;
    axCode: string;

    name: string;
    englishName: string;
    palletSizeCode: string;
    palletSizeName: string;
    grossWeightKG: number;
    netWeightKG: number;
    volumeL: number;
    density: number;
    adrClassName: string;
    adrClassCode: string;
    brandCode: string;
    brandName: string;
    axProductDimensionGroupCode: string;
    masterItemTypeCode: string;
    masterItemTypeName: string;
    masterBasicUMCode: string;
    companyDataAreaId: string;
    eanCode: string;
    chosenColorShade: ColorShade;
    baseCode: string;
    colorShadeRetrieveError = false;

    constructor(product: ProductWithInventoryAndQuantity) {
        this.quantity = product.Quantity;
        this.axCode = product.AXCode;
        this.bkProductKey = product.bkProductKey;
        this.name = product.Name;
        this.englishName = product.EnglishName;
        this.palletSizeCode = product.PalletSizeCode;
        this.palletSizeName = product.PalletSizeName;
        this.grossWeightKG = product.GrossWeightKG;
        this.netWeightKG = product.NetWeightKG;
        this.volumeL = product.VolumeL;
        this.density = product.Density;
        this.adrClassName = product.ADRClassName;
        this.adrClassCode = product.ADRClassCode;
        this.brandCode = product.BrandCode;
        this.brandName = product.BrandName;
        this.axProductDimensionGroupCode = product.AXProductDimensionGroupCode;
        this.masterItemTypeCode = product.MasterItemTypeCode;
        this.masterItemTypeName = product.MasterItemTypeName;
        this.masterBasicUMCode = product.MasterBasicUMCode;
        this.companyDataAreaId = product.DataAreaID;
        this.eanCode = product.EANCode;
        this.baseCode = product.BaseCode;
        this.colorShadeRetrieveError = false;
    }

    public static calculateProductPrice(product: PortalOrderProduct, priceItem: PriceListResponseItem) {
        let totalDiscount = 0;
        const totalNoDiscountAmount = priceItem.price * product.quantity;
        if (priceItem.discAmt > 0) {
            totalDiscount = priceItem.discAmt;
        }
        if (priceItem.discPct > 0) {
            totalDiscount = totalNoDiscountAmount * priceItem.discPct / 100;
        }
        product.unitNetPrice = priceItem.price - (totalDiscount / product.quantity);
        product.lineAmount = product.unitNetPrice * product.quantity;
    }
}
