import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CrmContact } from "../../models/contact";

@Injectable({
    providedIn: 'root'
})

export abstract class IContactService {
    public abstract getCurrentUsersContact(): Observable<CrmContact>;
}
