import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class StorageService<T> {

    public SaveToStorage(key: string, object: T): void {
        localStorage.setItem(key, JSON.stringify(object));
    }

    public GetFromStorageByKey(key: string): T {
        const storedObjectString = localStorage.getItem(key);
        if (!storedObjectString) {
            return null;
        }
        return JSON.parse(storedObjectString) as T;
    }

    public RemoveFromStorage(key: string): void {
        localStorage.removeItem(key);
    }

}
