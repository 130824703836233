import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerTaskResponse } from 'src/app/shared/models/customer-task-response';
import { CustomerTask } from 'src/app/shared/models/task';

Injectable()
export abstract class ITaskService {
    baseUrl: string;

    abstract getTaskById(taskId: string, accountId: string): Observable<CustomerTask>;
    abstract getTasksByAccountId(accountId: string): Observable<CustomerTask[]>;
    abstract sendCustomerResponse(customerResponse: CustomerTaskResponse): Observable<unknown>;
}
