import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SalesDistrict } from '../../constants/sales-district';
import { CosmosCustomer } from '../../models/customer';
import { CompanyCode } from '../../constants/company-code';

@Component({
  selector: 'ntw-customer-service-dialog',
  templateUrl: './customer-service-dialog.component.html',
  styleUrls: ['./customer-service-dialog.component.scss']
})
export class CustomerServiceDialogComponent implements OnInit, OnDestroy {
  @Input() public close: () => void;
  @Input() public customer: CosmosCustomer;

  public loading = true;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'no-scroll');
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }

  public isAlcroCustomer() {
    const salesDistrict = this.customer.SalesDistrict;
    return salesDistrict && salesDistrict.startsWith(SalesDistrict.AlcroSweden)
  }

  public isBeckersCustomer() {
    const salesDistrict = this.customer.SalesDistrict;
    return salesDistrict && salesDistrict.startsWith(SalesDistrict.BeckersSweden)
  }

  public isNorwayCustomer() {
    const companyCode = this.customer.TikkurilaCompanyRegisterCode;
    return companyCode === CompanyCode.Norway;
  }
}
