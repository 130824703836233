import { SVGIcon, clipboardTextIcon, homeIcon, listUnorderedIcon } from "@progress/kendo-svg-icons";

export const OrderProcessSteps: Step[] = [
    { stepName: 'warehouse', icon: homeIcon, label: 'orderProcess.steps.warehouse', text: '01' },
    { stepName: 'products', icon: listUnorderedIcon, label: 'orderProcess.steps.products', text: '02' },
    { stepName: 'summary', icon: clipboardTextIcon, label: 'orderProcess.steps.finalize', text: '03' }
];

export type StepName = 'warehouse' | 'products' | 'summary';

export interface Step {
    icon?: SVGIcon;
    label: string;
    stepName: StepName;
    text: string;
}
