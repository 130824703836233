import { BehaviorSubject } from 'rxjs';

export abstract class Store<T> {
  protected loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected cachedItem: BehaviorSubject<T | null | undefined> = new BehaviorSubject<T | null | undefined>(undefined);

  public isLoading(): BehaviorSubject<boolean> {
    return this.loading;
  }

  public isLoaded(): boolean {
    return this.cachedItem.getValue() !== undefined;
  }

  public get(): BehaviorSubject<T | null | undefined> {
    return this.cachedItem;
  }
}
