import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ColorShadeValue } from '../../models/colorshade/colorshade-value';
import { ODataResponse } from '../../models/odata-response';

@Injectable({
    providedIn: 'root'
})
export abstract class IColorshadeService {
    public abstract getColorShadesByEanCode(eanCode: string): Observable<ODataResponse<ColorShadeValue[]>>;

}
