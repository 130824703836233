import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityService } from "../security.service";

export const userRolesGuard: CanActivateFn = (
  _route,
  _state,
  securityService: SecurityService = inject(SecurityService),
  router: Router = inject(Router)
) => {
  return new Observable<boolean | UrlTree>(obs => {
    if (securityService.hasAnyRole()) {
      obs.next(true);
    } else {
      obs.next(router.parseUrl('/security'));
    }
  });
}
