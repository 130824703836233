import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettings } from '../models/user-settings';
import { IUserSettingsService } from '../services/user/user-settings.service.interface';
import { Store } from './store.base'

@Injectable({
  providedIn: 'root'
})
export class UserSettingsStore extends Store<UserSettings> {
  private userb2cId = '';

  constructor(private userSettingsService: IUserSettingsService) {
    super();
  }

  public override isLoaded(): boolean {
    const userSettings = this.cachedItem.getValue();
    return userSettings !== undefined && userSettings.id !== undefined;
  }

  public load(userb2cId: string): Observable<UserSettings | undefined> {
  if (this.userb2cId === userb2cId) {
    return this.get();
  }

  this.loading.next(true);

  this.userSettingsService.get(userb2cId).subscribe(settings => {
    if (settings !== undefined) {
      this.cachedItem.next(settings);
      this.userb2cId = userb2cId;
    }
    this.loading.next(false);
  });

    return this.get();
  }
}
