import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ntw-list-item',
    template: `
        <div class="w-100 d-flex justify-content-center" (click)="this.clicked()">
            <div class="mt-2 item" [ngClass]="{'item-selected': this.isChecked, 'item-disabled': this.isReadOnly}">
                <div class="d-flex col-2 col-sm-1">
                    <input type="checkbox" kendoCheckBox [disabled]="this.isReadOnly"
                    (ngModelChange)="isCheckedChange.emit($event)"
                    (change)="OnCheckBoxClicked(this.dataItem)" [(ngModel)]="isChecked"/>
                </div>
                <div class="col-10 col-sm-11 decima-mono-font">
                    <div class="item-name">{{ this.ItemName }}</div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .item {
            width: calc(100% - 10px);
            padding-left: 1rem;
            display: flex;
            align-items: center;
            background-color: var(--color-bg-default);
            box-sizing: border-box;
            height: 45px;
            border-radius: 7px;
            transition: height .2s ease, width .2s ease;
        }
        .item:hover:not(.item-disabled) {
            height: 50px;
            width: 100%;
            cursor: pointer;
            transition: height .2s ease, width .2s ease;
        }
        .item-disabled {
            opacity: 0.8;
            cursor: default;
        }
        .item-selected{
            border: 1px solid var(--color-brand-primary-active);
            background-color: var(--color-brand-primary-default-opacity-025);
            transition: height .2s ease, width .2s ease;
        }
        .description {
            flex: 1;
        }
        .item-name {
            font-size: 14px;
        }
    `]
})

export class ListItemComponent {
    @Input() public ItemName: string;

    @Input() public isReadOnly: boolean = false;
    @Input() public isChecked: boolean;
    @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public dataItem;
    @Input() public OnCheckBoxClicked: (item) => void;

    public clicked() {
        if (this.isReadOnly) {
            return;
        }
        this.isChecked = !this.isChecked;
        this.isCheckedChange.emit(this.isChecked);
        this.OnCheckBoxClicked(this.dataItem);
    }
}
