import { Directive, EventEmitter, Output } from '@angular/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { debounceTime } from 'rxjs';

/**
 * Directive
 * Provides the afterValueChanged event for kendo-textbox
 */
@Directive({
    selector: 'kendo-textbox[ntwExtended]'
})
export class TextBoxExtensionDirective {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() afterValueChanged: EventEmitter<any> = new EventEmitter();

    constructor(private textbox: TextBoxComponent) {
        this.textbox.valueChange.pipe(debounceTime(1000)).subscribe(value => this.afterValueChanged.emit(value));
    }
}
