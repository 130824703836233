export enum StringConstants {
    B2CExtensionAttribute_Rights_CustomerPortal = "customerportal",
    BkInvoiceAccountKeyField = "bkInvoiceAccountKey",
    CommissionGroupField = "CommisionGroupId",
    CommissionGroupAll = "NA",
    CommissionGroupAlcro = "A",
    CommissionGroupBeckers = "B",
    LatestColorShadeStorage = "LatestColorShade",
    MasterItemTypeCodeField = "MasterItemTypeCode",
    ProductActiveStatusValue = "Active",
    ProductDataAreaField = "DataAreaID",
    ProductDiscontinuedStatusValue = "Discontinued",
    ProductReleasedItemLifeCycleAxStatusField = "ReleasedItemLifeCycleAxStatus",
    ProductSharedItemLifeCycleAxStatusField = "SharedItemLifeCycleAxStatus",
    ProductToBeDiscontinuedStatusValue = "To be discontinued",
    SalesArticlesMasterItemTypeCode = "60",
    TintableAXProductDimensionGroupCode = "TintdPaint",
    VisibleInWebShopField = "VisibleInWebShop",
    VisibleInWebShopYes = "YES",
}
