import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { openOrdersListLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { GridNames } from 'src/app/shared/constants/grid-names';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from 'src/app/shared/services/filter-sort-description.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { GridPersistService } from 'src/app/shared/services/ui/grid-persist.service';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { Utils } from 'src/app/shared/utils';
import { IPortalOrderService } from '../services/portal-orders.service.interface';
import { PortalOrderColumns } from './portal-order-columns';

@Component({
  selector: 'ntw-portal-order-list',
  templateUrl: './portal-order-list.component.html',
})
export class PortalOrderListComponent extends KendoGridBase<PortalOrder> implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  currentAccount: CrmAccount;
  currentCustomer: CosmosCustomer;
  columnSettings: ColumnSettings[];
  totalValues: TitleValue[];
  selectedOrder: PortalOrder = undefined;
  formConfig: FormConfiguration;

  public initialLoad = true;

  constructor(
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private customerService: ICustomerService,
    private filterSortService: FilterAndSortDescriptionService,
    private gridPersistService: GridPersistService,
    private kendoAlertService: KendoAlertService,
    private portalOrdersService: IPortalOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private securityService: SecurityService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.kendoGridState = this.gridPersistService.GetGridState(GridNames.portalOrderGrid, this.kendoGridState);
    this.companyAdminConfigurationStore.getFormConfiguration(openOrdersListLineColumnsFormName).pipe(concatMap(formConfig => {
      this.formConfig = formConfig;
      return this.securityService.getCurrentAccount();
    }), concatMap(account => {
      this.currentAccount = account;
      return this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode);
    })).subscribe({
      next: customer => {
        this.currentCustomer = customer;
        this.columnSettings = FormConfiguration.applyToColumnSettings(this.formConfig, [...PortalOrderColumns]);
        this.setTotalValues();
        this.setDefaultFiltersAndSort();
        this.getEntitiesForAccount(this.currentAccount?.ntw_axcode);
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
    this.setupLanguageChangeHandler();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  override dataStateChange = (state: DataStateChangeEvent) => {
    this.kendoGridState = state;
    this.gridPersistService.SaveGridState(GridNames.portalOrderGrid, state);
    this.getEntitiesForAccount(this.currentAccount?.ntw_axcode);
  }

  public getEntitiesForAccount(accountKey: string) {
    if (!accountKey) {
      this.entities = [];
      this.loading = false;
      return;
    }

    this.loading = true;
    this.portalOrdersService.GetCustomerPortalOrdersWithGridState(accountKey, this.kendoGridState).subscribe({
      next: portalOrders => {
        this.loading = false;
        if (portalOrders && (!this.initialLoad || portalOrders.length > 0)) {
          portalOrders.forEach(portalOrder => this.setOrderNumberField(portalOrder));
          this.entities = portalOrders;
          this.generateGridDataView();
        }
        else if (this.initialLoad) {
          this.router.navigate(['../draftorder'], { relativeTo: this.route });
          this.kendoAlertService.showInfoAlert(this.translateService.instant("portalOrder.noOrdersFound"));
        }
        this.initialLoad = false;
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error.error);
        this.loading = false;
      }
    });
  }

  private setOrderNumberField = (portalOrder: PortalOrder) => {
    portalOrder.orderNumber = (portalOrder.portalOrderId ?? portalOrder.id).split('_')[0];
  }

  private setDefaultFiltersAndSort() {
    this.setCreatedOnDecreasingSorting();
  }

  private setCreatedOnDecreasingSorting() {
    const createdOnSortDesc = this.filterSortService.createSortDescriptor('CreatedOn', 'desc');
    this.filterSortService.setDefaultSortDescriptor(this.kendoGridState, createdOnSortDesc);
  }

  openSelectedPortalOrder = () => {
    if (!this.selectedOrder || !this.selectedOrder.id) {
      return;
    }
    this.openPortalOrder(this.selectedOrder);
  }

  openPortalOrder = (portalOrder: PortalOrder) => {
    if (portalOrder.orderSubmissionStatus == 0) {
      this.router.navigate([`account/${this.currentAccount.accountid}/draftorder/${portalOrder.portalOrderId ?? portalOrder.id}`]);
    }
    else if (portalOrder.orderSubmissionStatus == 1) {
      this.router.navigate([`./${portalOrder.portalOrderId ?? portalOrder.id}`], { relativeTo: this.route });
    }
  }

  public generateSummary = (orders: PortalOrder[]) => {
    this.summaries = [];
    this.summaries = this.graphData.GetGraphDataForNewOrders(orders);
    this.setTitleValues(orders);
  }

  onSearchEntered = (enteredValue: string) => {
    if (!enteredValue) {
      this.generateGridDataView();
      return;
    }

    const queryLowered = enteredValue.toLowerCase();
    const filterExpression = (item: PortalOrder) => {
      return item.customerRequisition.toLowerCase().indexOf(queryLowered) !== -1
        || item.customerReference.toLowerCase().indexOf(queryLowered) !== -1
        || item.id.toLowerCase().indexOf(queryLowered) !== -1
        || (item.warehouse?.warehouseName?.toLowerCase().indexOf(queryLowered) ?? -1) !== -1
        || (item.deliveryAddress?.city?.toLowerCase().indexOf(queryLowered) ?? -1) !== -1
        || (item.deliveryAddress?.countryRegion?.toLowerCase().indexOf(queryLowered) ?? -1) !== -1
        || (item.deliveryAddress?.zipCode?.toLowerCase().indexOf(queryLowered) ?? -1) !== -1
        || (item.requestedDeliveryDate?.toDateString()?.toLowerCase().indexOf(queryLowered) ?? -1) !== -1
    };

    const filteredView = this.entities.filter(filterExpression);
    this.dataGridView = process(filteredView, this.kendoGridState);
  }

  public setTitleValues(orders: PortalOrder[]) {
    let totalValue = 0;
    let totalVat = 0;
    let totalWithVat = 0;

    if (orders.length == 0) {
      this.totalValues.forEach(x => {
        x.value = "shared.notApplicable";
        x.subtitle = "shared.norecordsselected";
      });
      return;
    }
    else if (orders.length > 0) {
      this.totalValues.forEach(x => {
        x.subtitle = "shared.selectedrecords"
      });
    }

    orders.forEach(order => {
      if (order.totalValue) {
        totalValue += order.totalValue;
      }
      if (order.totalValueWithVat) {
        totalWithVat += order.totalValueWithVat;
      }
      if (order.totalVat) {
        totalVat += order.totalVat;
      }
    });

    if (this.totalValues.length > 0) {
      this.totalValues[0].value = Utils.FormatNumber(totalValue, 2);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].value = Utils.FormatNumber(totalVat, 2);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].value = Utils.FormatNumber(totalWithVat, 2);
    }

    this.translateTitleValues();
  }

  private translateTitleValues(): void {
    if (this.totalValues.length > 0) {
      this.totalValues[0].title = Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValue"), this.currentCustomer);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].title = Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalVat"), this.currentCustomer);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].title = Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValueWithVat"), this.currentCustomer);
    }
  }

  public deleteOrder = (order: PortalOrder) => {
    this.loading = true;
    this.portalOrdersService.DeleteOrder(order).subscribe({
      next: () => {
        this.kendoAlertService.showSuccessAlert(this.translateService.instant("alerts.recordDeleted", true));
        this.getEntitiesForAccount(this.currentAccount?.ntw_axcode);
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.loading = false;
      }
    });
  }

  public showOpenButton() {
    return true;
  }

  public isOrderEditable(order: PortalOrder) {
    return order.orderSubmissionStatus === 0;
  }

  public isOrderDeletable(order: PortalOrder) {
    return order.orderSubmissionStatus === 0;
  }

  private setTotalValues() {
    const totalValues: TitleValue[] = [
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValue"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "totalValue"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalVat"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "totalVat"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValueWithVat"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "totalValueWithVat"
      },
    ];

    this.totalValues = FormConfiguration.applyToTitleValues(this.formConfig, totalValues);
  }

  private setupLanguageChangeHandler() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      if (this.entities && this.entities.length) {
        this.entities.forEach(po => this.portalOrdersService.setStatusName(po));
        this.generateGridDataView();
      }
      this.translateTitleValues();
    })
  }
}
