import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/internal/Observable';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { OrderHeader } from 'src/app/shared/models/orders/order-header';

@Injectable()
export abstract class IOrderService {
    baseUrl: string;
    abstract getOrdersWithGridState(bkInvoiceAccountKey: string, gridState: State, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<OrderHeader[]>>;
    abstract getOrderDetail(orderId: string, bkInvoiceAccountKey: string): Observable<OrderHeader>
    abstract getOrderAgentReference(orderId: string, accountKey: string): Observable<string>;
}
