import { Component, Input, OnInit } from "@angular/core";
import { TitleValue } from "../models/ui/title-value";

@Component({
    selector: 'ntw-title-value',
    templateUrl: 'title-value.component.html',
})

export class TitleValueComponent implements OnInit {

    @Input() titleValue: TitleValue
    @Input() title: string;
    @Input() value;
    @Input() subtitle: string;

    ngOnInit(): void {
        this.initTitleValue();
    }

    initTitleValue() {
        if (this.titleValue) {
            if (typeof (this.titleValue.value) == 'boolean') {
                if (this.titleValue.value) {
                    this.titleValue.value = "shared.true";
                }
                else {
                    this.titleValue.value = "shared.false";
                }
            }
            else {
                if (!this.titleValue.value) {
                    this.titleValue.value = "shared.notApplicable";
                }
            }
            return;
        }
        if (!this.title) {
            return;
        }
        if (!this.value) {
            this.value = "shared.notApplicable";
        }
        this.titleValue = {
            title: this.title,
            value: this.value,
            subtitle: this.subtitle
        }
    }
}
