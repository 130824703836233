/* eslint-disable @typescript-eslint/no-explicit-any */
import { Portal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NewOrderAddress } from '../../models/address/address';
import { PortalOrderProduct } from '../../models/order-creation/portal-order-product';
import { Selection } from '../../models/order-creation/selection';
import { InventoryOnline } from '../../models/products/inventory';
import { Warehouse } from '../../models/warehouse';
import { searchIcon } from '@progress/kendo-svg-icons';

@Component({
    templateUrl: './select-item.component.html',
    selector: 'ntw-select-item-component',
    styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent implements OnInit, OnChanges {
    /**
     * Works only if type is not 'product-item'
     */
    @Input() isReadOnly: boolean = false;
    @Input() idField: string;
    @Input() title: string;
    @Input() isHeaderOptionVisible: boolean;
    @Input() headerOptionTitle: string;
    @Input() getInventoryByProduct: (dataItem: PortalOrderProduct) => InventoryOnline;

    public icons = {
        search: searchIcon
    }

    public portalForWindow: Portal<any>;

    private _items: Selection[] = [];

    @Input() set items(value: Selection[]) {
        this._items = value;
        this.displayedItems = this._items.concat(this.deletedItems);
    }

    get items(): Selection[] {
        return this._items;
    }

    @Input() type: string;

    private _displayedItems: Selection[] | any[] = this.items;

    set displayedItems(value: Selection[] | any[]) {
        this._displayedItems = value.sort((a, b) => a['orderProductNumber'] - b['orderProductNumber']);
    }

    get displayedItems(): Selection[] | any[] {
        return this._displayedItems;
    }
    deletedItems: Selection[] | any[] = [];

    @Input() selectedItem: Selection | any;
    @Output() selectedItemChange = new EventEmitter<Selection | any>();

    @Input() isHeaderOptionChecked: boolean;
    @Output() isHeaderOptionCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public OnHeaderOptionCheckedChanged = ($event: boolean) => {
        this.items.forEach(x => x.isChecked = false);
        this.isHeaderOptionCheckedChange.emit($event);
    }

    ngOnInit(): void {
        this.displayedItems = this.items.concat(this.deletedItems);
        this.setDefault();
    }

    ngOnChanges(): void {
        this.displayedItems = this.items.concat(this.deletedItems);
    }

    public handleFilterChange(query: string) {
        if (!query) {
            this.displayedItems = this.items.concat(this.deletedItems);
            return;
        }
        const queryLowered = query.toLowerCase();
        let filterExpression;
        switch (this.type) {
            case 'address':
                filterExpression = (item: NewOrderAddress) => {
                    return item.city.toLowerCase().indexOf(queryLowered) !== -1
                        || item.countryRegion.toLowerCase().indexOf(queryLowered) !== -1
                        || item.zipCode.toLowerCase().indexOf(queryLowered) !== -1
                        || item.street.toLowerCase().indexOf(queryLowered) !== -1;
                };
                break;
            case 'item':
                filterExpression = (item: { name: string; code: string; }) => {
                    return item.name.toLowerCase().indexOf(queryLowered) !== -1
                        || item.code.toLowerCase().indexOf(queryLowered) !== -1;
                };
                break;
            case 'product-item':
                filterExpression = (item: PortalOrderProduct) => {
                    return item?.name.toLowerCase().indexOf(queryLowered) !== -1
                        || item?.axCode.toLowerCase().indexOf(queryLowered) !== -1;

                };
                break;
            case 'warehouse':
                filterExpression = (item: Warehouse) => {
                    return item.warehouseName.toLowerCase().indexOf(queryLowered) !== -1;
                };
                break;
            default:
                break;
        }

        this.displayedItems = this.items.filter(filterExpression).concat(this.deletedItems.filter(filterExpression));
    }

    public OnCheckBoxClicked = (item) => {
        this.selectedItem = item;
        const checkedItem = this.items.filter(x => x !== item && x['isChecked'] == true);
        checkedItem.forEach(x => x['isChecked'] = false);
        if (!this.selectedItem['isChecked']) {
            this.selectedItem = null;
        }
        this.selectedItemChange.emit(this.selectedItem);
    }

    public OnCopyClicked = (item) => {
        const index = this.items.indexOf(item, 0);
        if (index > -1) {
            this.items.splice(index + 1, 0, { ...item });
        }
        this.selectedItemChange.emit();
    }

    public OnDeleteClicked = (item) => {
        this.deletedItems.push(item);
        const index = this.items.indexOf(item, 0);
        if (index > -1) {
            this.items.splice(index, 1);
        }
        this.selectedItemChange.emit();
    }

    public IsItemDeleted = (item) => {
        return this.deletedItems.indexOf(item) > -1;
    }

    public OnUndoClicked = (item) => {
        const index = this.deletedItems.indexOf(item, 0);
        if (index > -1) {
            this.deletedItems.splice(index, 1);
        }
        this.items.push(item);
        if (this.type === 'product-item') {
            this.items = this.items.sort((a, b) => a['orderProductNumber'] - b['orderProductNumber']);
        }
        this.selectedItemChange.emit();
    }

    public OnQuantityChanged = () => {
        this.selectedItemChange.emit();
    }

    private setDefault() {
        if (!this.selectedItem) {
            return;
        }
        const itemFromList = this.items.find(x => x[this.idField] == this.selectedItem[this.idField]);
        if (!itemFromList) { return; }
        itemFromList['isChecked'] = true;
    }
}
