<ntw-pdf-viewer-dialog *ngIf="this.pdfViewerDialogVisible" [close]="this.closePdfViewerDialog"
    title="{{'orderProcess.summary.termsAndConditions' | translate | uppercase}}"
    [pdfSrc]="this.termsAndConditionsPath">
</ntw-pdf-viewer-dialog>
<div class="row mt-3">
    <div class="col col-lg-10 pb-2">
        <label class="headerBig">{{ 'orderProcess.summary.orderInformation' | translate }}</label>
    </div>
</div>
<ntw-order-select-details class="mb-5" [(portalOrder)]="portalOrder" (portalOrderChange)="emitPortalOrderChange($event)"
    [missingOrderFields]="this.missingOrderFields">
</ntw-order-select-details>
<div class="row mt-5 mb-3">
    <div class="col col-lg-10 pb-2">
        <label class="headerBig">{{ 'orderProcess.summary.orderLines' | translate }}</label>
    </div>
</div>
<ntw-grid-template class="mb-5" [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings"
    [selectableSettings]="{enabled: false}" idFieldName="axCode" [entities]="this.entities"
    [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
    [dataStateChange]="this.dataStateChange" [kendoGridState]="this.kendoGridState" [showCheckBoxColumn]="false"
    [showMenu]="false" [drillDownEnabled]="false" [isEditingSelectedEntities]="true"
    [createFormGroup]="this.createFormGroup" [showButtonsColumn]="true" [showDeleteButton]="true"
    [deleteItem]="deleteProduct">
</ntw-grid-template>
<div class="row mt-5">
    <div class="col col-lg-10 pb-2">
        <label class="headerBig">{{ 'orderProcess.summary.orderTotals' | translate }}</label>
    </div>
</div>
<div class="row mx-2 my-3" *ngIf="firstRow && firstRow.length > 0">
    <ng-container *ngFor="let value of firstRow">
        <div
            [ngClass]="{'col-12 col-sm-4': firstRow.length <= 3, 'col-12 col-sm-3': firstRow.length > 3 || secondRow.length > 3}">
            <ntw-title-value [titleValue]="value"></ntw-title-value>
        </div>
    </ng-container>
</div>
<div class="row mx-2 mt-3 mb-5" *ngIf="secondRow && secondRow.length > 0">
    <ng-container *ngFor="let value of secondRow">
        <div
            [ngClass]="{'col-12 col-sm-4': secondRow.length <= 3, 'col-12 col-sm-3': firstRow.length > 3 || secondRow.length > 3}">
            <ntw-title-value [titleValue]="value"></ntw-title-value>
        </div>
    </ng-container>
</div>
<div class="row mt-5" *ngIf="submitVisible()">
    <div class="col col-lg-10 pb-2">
        <label class="headerBig">{{ 'orderProcess.summary.confirmAndSend' | translate }}</label>
    </div>
</div>
<div class="row mt-2 mx-2" *ngIf="submitVisible()">
    <div class="col">
        <input type="checkbox" class="terms-and-conditions-checkbox" #termsAndConditionsCheckbox2
            [(ngModel)]="this.isTermsAndConditionsAccepted" kendoCheckBox required />
        <kendo-label class="k-checkbox-label me-2">
            <label class="k-label terms-and-conditions-label"
                [for]="termsAndConditionsCheckbox2">{{'orderProcess.summary.termsAndConditionsRead' |
                translate}}</label>
            <a (click)="this.openPdfViewerDialog()" class="k-link">{{'orderProcess.summary.termsAndConditions' |
                translate}}</a>
        </kendo-label>
    </div>
</div>
<div class="row my-3 mx-2" *ngIf="submitVisible()">
    <div class="col">
        <button kendoButton class="primaryButton fw-bold" [svgIcon]="this.icons.envelope"
            [disabled]="!submitButtonEnabled()" (click)="submitOrder()">{{"orderProcess.submitOrder" |
            translate}}
        </button>
    </div>
</div>