<div *ngIf="!areAccountsLoading" #contextTreeToggleButton class="d-flex k-cursor-pointer" (click)="expandAccountSelection()">

    <p *ngIf="selectedAccount" class='ps-0 inline select-context'>{{selectedAccount.name}}</p>
    <p *ngIf="!selectedAccount" class='ps-0 inline select-context'>{{ 'navigationTopBar.selectContext' | translate }}</p>

    <button class="me-2 k-button k-button-clear inline select-context-arrow">
        <kendo-svg-icon [icon]="icons.chevrons.chevronDown" [size]="'small'"></kendo-svg-icon>
    </button>

</div>

<div *ngIf="isAccountSelectionVisible && loading" class="k-i-loading select-context-treeview"></div>

<kendo-treeview *ngIf="isAccountSelectionVisible && !loading" #treeView [nodes]="contextData" textField="name"
    class="select-context-treeview" kendoTreeViewExpandable kendoTreeViewSelectable kendoTreeViewFlatDataBinding
    idField="accountid" parentIdField="_parentaccountid_value" (selectionChange)="handleSelection($event)"
    [(selectedKeys)]="selectedKeys" [expandedKeys]="expandedKeys">
</kendo-treeview>