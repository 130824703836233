<ntw-loading-panel *ngIf="loading"></ntw-loading-panel>
<ntw-select-warehouse-dialog *ngIf="showSelectWarehouseDialog" [warehouses]="warehouseDropdownListData"
    (warehouseSelected)="selectWarehouseDialogClosed($event)">
</ntw-select-warehouse-dialog>
<form class="accountData" *ngIf="customer && !loading">
    <div class="container">
        <div class="row header">
            <div class="col">
                <label class="headerBig"> {{customer.CustomerName}} </label>
            </div>
            <div class="col-1 col-sm-6 col-md-auto d-flex justify-content-end align-items-center">
                <ntw-loading-button class="mx-2 d-none d-md-block" buttonClass="primaryButton"
                    (click)="printAccountStatement()" [disabled]="!canCreatePrintAccountStatementRequest()"
                    [loading]="isSubmittingPrintAccountStatementRequest"
                    [text]="'account.printAccountStatement' | translate">
                </ntw-loading-button>
                <button class="collapseButton inline" (click)="isCollapsed = !isCollapsed"
                    [@rotate]="isCollapsed ? 'collapsed' : 'expanded'" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample">
                    <kendo-svg-icon [icon]="icons.chevronDown" size="xxlarge"></kendo-svg-icon>
                </button>
            </div>
        </div>
        <div class="row details row-cols-1 row-cols-sm-2 gy-3 pt-0" [@collapse]="isCollapsed ? 'closed' : 'open'">
            <div class="col w-100 d-md-none mt-3">
                <ntw-loading-button class="w-100 printButton" buttonClass="primaryButton w-100"
                    buttonStyle="height: 36px;" (click)="printAccountStatement()"
                    [disabled]="!canCreatePrintAccountStatementRequest()"
                    [loading]="isSubmittingPrintAccountStatementRequest"
                    [text]="'account.printAccountStatement' | translate">
                </ntw-loading-button>
            </div>
            <div class="col">
                <div class="row row-cols-1 row-cols-sm-2 gy-sm-3">
                    <div class="col">
                        <label class="labelGrey">{{ 'account.axcode' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.AXCode}}</label>
                    </div>
                    <div class="col mt-2 mt-sm-3">
                        <label class="labelGrey">{{ 'account.currencycode' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.CurrencyCode}}</label>
                    </div>
                    <div class="col mt-2 mt-sm-3">
                        <label class="labelGrey">{{ 'account.paymentterms' | translate }}</label>
                    </div>
                    <div class="col">
                        <label class="lableBlack fw-bolder">{{customer.PaymentTermDescription}}</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-cols-1 row-cols-sm-2 gy-sm-3">
                    <div class="col mt-0 mt-sm-3">
                        <label class="labelGrey">{{ 'account.legaladdress' | translate }}</label>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.StreetAddress1}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.PostalCode}} {{customer.City}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="lableBlack fw-bolder">{{customer.CountryName}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col mt-2">
                        <label class="labelGrey">{{ 'account.yourWarehouse' | translate }}</label>
                    </div>
                    <div class="col mt-1 mt-sm-2">
                        <ntw-loading-dropdownlist [loading]="!warehouses || warehouses.length === 0"
                            [disabled]="!warehouses || warehouses.length === 0 || warehouses.length === 1"
                            [showNgContentOnDisabled]="true"
                            [defaultItem]="prefferedWarehouse ?? { warehouseName: 'account.selectWarehouse' | translate }"
                            (valueChange)="selectedWarehouseChanged($event).subscribe()"
                            [data]="warehouseDropdownListData" textField="warehouseName" valueField="bkWarehouseKey">
                            <label class="lableBlack fw-bolder">{{prefferedWarehouse?.warehouseName ?? ''}}</label>
                        </ntw-loading-dropdownlist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="container my-3" *ngIf="!loading">
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 function-rows justify-content-between">
        <div class="col col-md-12 col-xl-4">
            <div class="row header function-header">
                <div class="col">
                    <label class="headerBig">{{ 'product.findProducts' | translate | uppercase }}</label>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <kendo-svg-icon [icon]="icons.search" size="xxlarge"></kendo-svg-icon>
                </div>
            </div>
            <div class="row function-details">
                <div class="col">
                    <kendo-textbox size="large" placeholder="{{'product.searchForProducts' | translate}}"
                        (valueChange)="searchValueChange($event)" (keydown.enter)="searchForProducts()">
                        <ng-template kendoTextBoxSuffixTemplate>
                            <button kendoButton class="primaryButton searchButton" [disabled]="!searchValue"
                                (click)="searchForProducts()">
                                {{ 'shared.search' | translate }}
                            </button>
                        </ng-template>
                    </kendo-textbox>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row header function-header">
                <div class="col">
                    <label class="headerBig">{{ 'routing.userGuide' | translate | uppercase }}</label>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <kendo-svg-icon [icon]="icons.questionMark" size="xxlarge"></kendo-svg-icon>
                </div>
            </div>
            <div class="row function-details justify-content-end">
                <div class="col-6 ps-0 ps-sm-2">
                    <button kendoButton size="large" class="primaryButton goButton w-100" (click)="onUserGuideClick()">
                        <div class="row w-100">
                            <div class="col-auto d-flex align-items-center">
                                <kendo-svg-icon [icon]="icons.go" size="large"></kendo-svg-icon>
                            </div>
                            <div class="col">
                                {{ 'shared.go' | translate }}
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row header function-header">
                <div class="col">
                    <label class="headerBig">{{ 'routing.openOrders' | translate | uppercase }}</label>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <kendo-svg-icon [icon]="icons.cart" size="xxlarge"></kendo-svg-icon>
                </div>
            </div>
            <div class="row function-details justify-content-end">
                <div class="col-6 ps-0 ps-sm-2">
                    <button kendoButton size="large" class="primaryButton goButton w-100" (click)="onMyCartClick()">
                        <div class="row w-100">
                            <div class="col-auto d-flex align-items-center">
                                <kendo-svg-icon [icon]="icons.go" size="large"></kendo-svg-icon>
                            </div>
                            <div class="col">
                                {{ 'shared.go' | translate }}
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ntw-dashboard-card class="my-3" *ngFor="let card of dashboardCards" [card]="card"></ntw-dashboard-card>