import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const invoiceLinesColumns: ColumnSettings[] = [
    {
        field: "SalesLine", width: 55, minResizableWidth: 55,
        title: "invoiceLine.SalesLine"
    },
    {
        field: "bkProductKeyNoCode", width: 55, minResizableWidth: 55,
        title: "invoiceLine.bkProductKey"
    },
    {
        field: "ItemDescription", width: 90, minResizableWidth: 80,
        title: "invoiceLine.ItemDescription"
    },
    {
        field: "SalesQty", width: 55, filter: 'numeric', minResizableWidth: 55,
        title: "invoiceLine.SalesQty"
    },
    {
        field: "TLAUnitPriceAfterDisc", width: 50, filter: 'numeric', minResizableWidth: 50,
        title: "invoiceLine.TLAUnitPriceAfterDisc", format: "{0:n2}",
    },
    {
        field: "LineAmount", width: 55, filter: 'numeric', minResizableWidth: 55,
        title: "invoiceLine.LineAmount", format: "{0:n2}",
    },
    {
        field: "SalesL", width: 55, filter: 'numeric', minResizableWidth: 55,
        title: "invoiceLine.SalesL", format: "{0:n2}",
    },
    {
        field: "SalesNetKG", width: 55, filter: 'numeric', minResizableWidth: 55,
        title: "invoiceLine.SalesNetKG", format: "{0:n2}",
    },
    {
        field: "SalesGrossKG", width: 55, filter: 'numeric', minResizableWidth: 55,
        title: "invoiceLine.SalesGrossKG", format: "{0:n2}",
    },
    {
        field: "SalesOrderNum", width: 60, minResizableWidth: 60,
        title: "invoiceLine.SalesOrderNum"
    },
    {
        field: "SalesAgentReference", width: 70, minResizableWidth: 60,
        title: "invoiceLine.SalesAgentReference"
    },
    {
        field: "CustomerReference", width: 65, minResizableWidth: 60,
        title: "invoiceLine.CustomerReference"
    },
    {
        field: "CustomerRequisition", width: 65, minResizableWidth: 60,
        title: "invoiceLine.CustomerRequisition"
    },
];
