import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const productColumns: ColumnSettings[] = [
    {
        field: "axCode", width: 80, minResizableWidth: 80,
        title: "portalOrderLine.AXCode"
    },
    {
        field: "name", width: 200, minResizableWidth: 200,
        title: "portalOrderLine.name"
    },
    {
        field: "chosenColorShade.Code", width: 70, minResizableWidth: 70,
        title: "portalOrderLine.color"
    },
    {
        field: "brandName", width: 90, minResizableWidth: 60,
        title: "portalOrderLine.brandName"
    },
    {
        field: "unitNetPrice", width: 70, minResizableWidth: 60, filter: 'numeric',
        title: "portalOrderLine.unitNetPrice", format: "{0:n2}"
    },
    {
        field: "quantity", width: 70, minResizableWidth: 60, filter: 'numeric',
        title: "portalOrderLine.quantity"
    },
    {
        field: "masterBasicUMCode", width: 70, minResizableWidth: 60,
        title: "portalOrderLine.masterBasicUMCode"
    },
    {
        field: "lineAmount", width: 70, minResizableWidth: 60, filter: 'numeric',
        title: "portalOrderLine.lineAmount", format: "{0:n2}"
    },
    {
        field: "netWeightKG", width: 90, minResizableWidth: 60, filter: 'numeric',
        title: "portalOrderLine.netWeightKG", format: "{0:n2}"
    },
    {
        field: "grossWeightKG", width: 90, minResizableWidth: 60, filter: 'numeric',
        title: "portalOrderLine.grossWeightKG", format: "{0:n2}"
    },
    {
        field: "volumeL", width: 90, minResizableWidth: 60, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.volumeL"
    },
    {
        field: "palletSizeName", width: 90, minResizableWidth: 60,
        title: "portalOrderLine.palletSizeName"
    },
];
