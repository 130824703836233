<ntw-loading-panel *ngIf="initialLoad"></ntw-loading-panel>
<ntw-grid-template *ngIf="!initialLoad" gridTitle="portalOrder.title" [totalValues]="this.totalValues"
    graphTitle="order.graph.title" [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings"
    idFieldName="id" [entities]="this.entities" [dataGridView]="this.dataGridView" [summaries]="this.summaries"
    [loading]="this.loading" [dataStateChange]="this.dataStateChange" [generateSummary]="this.generateSummary"
    [kendoGridState]="this.kendoGridState" (afterValueChanged)="this.onSearchEntered($event)"
    [onDoubleClick]="this.openSelectedPortalOrder" [(selectedEntity)]="this.selectedOrder" [showButtonsColumn]="true"
    [showOpenButton]="true" [isItemEditable]="this.isOrderEditable" [onOpenButtonClicked]="this.openPortalOrder"
    [showDeleteButtonFn]="this.isOrderDeletable" [deleteItem]="this.deleteOrder"
    [itemViableForDelete]="this.isOrderDeletable">
</ntw-grid-template>