import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { LanguageCodes, NameFieldLanguageCodes } from 'src/app/shared/constants/language-codes';
import { Selectors } from 'src/app/shared/constants/selectors';
import { KendoUtils } from 'src/app/shared/kendo-utils';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { Product } from 'src/app/shared/models/products/product';
import { CustomLanguageService } from 'src/app/shared/services/language.service';
import { environment } from 'src/environments/environment';
import { IProductService } from './product.service.interface';

@Injectable({
    providedIn: 'root'
})

export class ProductService implements IProductService {
    baseUrl: string;

    selectors = {
        listViews: {
            productsView: "AXCode,Name,EnglishName,NameLC,BrandName,UsageAreaName,FamilyName,PalletSizeName",
            selectProductsView: "AXCode,EnglishName,NameLC,Name,FamilyName,ProductClassName,bkProductKey,PalletSizeCode,PalletSizeName,"
                + "GrossWeightKG,NetWeightKG,VolumeL,Density,ADRClassName,ADRClassCode,BrandCode,BrandName,"
                + "MasterItemTypeName,MasterBasicUMCode,EANCode,BaseCode,BaseName,AXProductDimensionGroupCode,"
                + "DataAreaID,MasterItemTypeCode,ReleasedItemLifeCycleAxStatus,VisibleInWebShop,"
                + "CommisionGroupId"
        },
        detailsViews: {
            productDetails: "Name,AXCode,EANCode,"
                + "ADRClassName,BrandName,PalletSizeName,MasterItemTypeName,"
                + "VolumeL,GrossWeightKG,NetWeightKG,Density"
        }
    }

    constructor(private httpClient: HttpClient,
        private languageService: CustomLanguageService) {
        this.baseUrl = environment.apiBaseUrl + "products";
    }

    getProductByAxCode(axCode: string): Observable<Product> {
        let selectorQuery = `?$select=${this.selectors.detailsViews.productDetails}`;
        const translationNameField = this.getTranslationNameField();
        if (translationNameField) {
            selectorQuery = `${selectorQuery},${translationNameField},${translationNameField}_LC`;
        }

        const url = this.baseUrl
            + selectorQuery
            + `&$filter=AXCode eq '${axCode}' `
            + "&$top=1";

        return this.httpClient.get<ODataResponse<Product[]>>(url).pipe(
            concatMap(productResponse => {
                return this.translateProductNames(productResponse);
            }),
            map(response => {
                if (response && response.value && response.value[0]) {
                    return this.translateProductName(response.value[0], translationNameField);
                }
                return null;
            }));
    }

    getProductsWithFilters(gridState: State, selector: Selectors, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<Product[]>> {
        const oDataFilters = KendoUtils.toODataString(gridState, additionalFilters);

        let selectorQuery = '';
        switch (selector) {
            case Selectors.productsView:
                selectorQuery = this.selectors.listViews.productsView;
                break;
            case Selectors.selectProductsView:
                selectorQuery = this.selectors.listViews.selectProductsView;
                break;
            default:
                selectorQuery = this.selectors.listViews.productsView;
                break;
        }

        const translationNameField = this.getTranslationNameField();
        if (translationNameField) {
            selectorQuery = `${selectorQuery},${translationNameField},${translationNameField}_LC`;
        }

        let url = this.baseUrl + `?$select=${selectorQuery}` + "&" + oDataFilters;
        if (!url.includes('$filter')) {
            url += `&$filter=(bkProductKey ne null and bkProductKey ne 'N/A')`;
        }
        url += `&$count=true`;

        return this.httpClient.get<ODataResponse<Product[]>>(url)
            .pipe(
                concatMap(serverResponse => {
                    const productResponse = new ODataResponse<Product[]>(serverResponse);
                    if (productResponse?.count > 0 && productResponse?.value?.length > 0) {
                        return this.translateProductNames(productResponse);
                    }
                    else {
                        return of(productResponse);
                    }
                }),
            );
    }

    getTranslationNameField(): string {
        switch (this.languageService.currentLanguage) {
            case LanguageCodes.EnglishUSLanguageCode:
                return NameFieldLanguageCodes.EnglishUSNameField;
            case LanguageCodes.NorwegianLanguageCode:
                return NameFieldLanguageCodes.NorwegianNameField;
            case LanguageCodes.PolishLanguageCode:
                return NameFieldLanguageCodes.PolishNameField;
            case LanguageCodes.SwedishLanguageCode:
                return NameFieldLanguageCodes.SwedishNameField;
            case LanguageCodes.RussianLanguageCode:
                return NameFieldLanguageCodes.RussianNameField;
            default:
                return '';
        }
    }

    private translateProductNames(productsResponse: ODataResponse<Product[]>): Observable<ODataResponse<Product[]>> {
        const translationNameField = this.getTranslationNameField();
        if (!translationNameField) {
            return of(productsResponse);
        }

        productsResponse.value.forEach(product => this.translateProductName(product, translationNameField));
        return of(productsResponse);
    }

    private translateProductName(product: Product, translationNameField: string): Product {
        if (product[translationNameField] && product[translationNameField] != 'N/A') {
            product.Name = product[translationNameField];
        }
        return product;
    }
}
