import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationConfiguration } from '../../models/notification-configuration';
import { CompanyAdminConfigurationStore } from '../../stores/company-admin-configuration.store';

@Injectable({
  providedIn: 'root'
})
export class CompanyNotificationService {
  public notificationConfiguration: BehaviorSubject<NotificationConfiguration> = new BehaviorSubject<NotificationConfiguration>(undefined);
  public isNotificationActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private companyAdminConfigurationStore: CompanyAdminConfigurationStore) {
    this.companyAdminConfigurationStore.get().subscribe({
      next: companyAdminConfiguration => {
        if (companyAdminConfiguration?.notificationConfiguration?.isNotification) {
          const notificationConfiguration = companyAdminConfiguration.notificationConfiguration;
          this.notificationConfiguration.next(notificationConfiguration);

          const isNotification = this.getIsNotificationActive(notificationConfiguration);
          this.updateNotificationSubject(isNotification);
        }
      }
    });
  }

  private getIsNotificationActive(notificationConfiguration: NotificationConfiguration): boolean {
    if (!notificationConfiguration || !notificationConfiguration.isNotification || !notificationConfiguration.startTime) {
      return false;
    }

    const now = new Date();
    const startTime = notificationConfiguration.startTime;
    const endTime = notificationConfiguration.endTime;

    if (endTime) {
      return now >= startTime && now <= endTime;
    }
    return now >= startTime;
  }

  private updateNotificationSubject(isNotification: boolean): void {
    if (this.isNotificationActive.value !== isNotification) {
      this.isNotificationActive.next(isNotification);
    }

    const endTime = this.notificationConfiguration.value.endTime;
    if (this.isNotificationActive.value && endTime) {
      const now = new Date();
      const timeToNotificationEnd = endTime.getTime() - now.getTime();

      setTimeout(() => {
        this.updateNotificationSubject(this.getIsNotificationActive(this.notificationConfiguration.value));
      }, timeToNotificationEnd);
    }
  }
}
