import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAccountService } from 'src/app/account/services/account.service.interface';
import { AzureRightsRoleCodes } from "../constants/azure-rights-role-codes";
import { CrmAccount } from '../models/account';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private b2cUser: AccountInfo;
  private b2cUserRoles: string[];
  private currentAccount = new BehaviorSubject<CrmAccount>(null);
  private currentAccountObject: CrmAccount = null;

  constructor(private accountService: IAccountService) {
  }

  public setUser(user: AccountInfo) {
    this.b2cUser = user;
  }

  public setUserRoles(roles: string[]) {
    this.b2cUserRoles = roles;
  }

  public getUser(): AccountInfo {
    return this.b2cUser;
  }

  public getUserRoles(): string[] {
    return this.b2cUserRoles;
  }

  public isUserInRole(role: string): boolean {
    return this.b2cUserRoles && this.b2cUserRoles.find(o => o == role) != null;
  }

  public hasAnyRole(): boolean {
    return this.b2cUserRoles && this.b2cUserRoles.length > 0;
  }

  public canCreateOrders(): boolean {
    return this.isUserInRole(AzureRightsRoleCodes.CreateOrders);
  }

  public canViewInvoices(): boolean {
    return this.isUserInRole(AzureRightsRoleCodes.FullOrderAndInvoiceAccess)
      || this.isUserInRole(AzureRightsRoleCodes.LimitedInvoiceAccess)

  }
  public canViewOrders(): boolean {
    return this.isUserInRole(AzureRightsRoleCodes.FullOrderAndInvoiceAccess)
      || this.isUserInRole(AzureRightsRoleCodes.LimitedOrderAccess)
  }

  public reset() {
    this.b2cUser = null;
    this.b2cUserRoles = null;
    this.currentAccount = new BehaviorSubject<CrmAccount>(null);
    this.currentAccountObject = null;
  }

  public setCurrentAccount(currentAccount: CrmAccount) {
    this.currentAccount.next(currentAccount);
    this.currentAccountObject = currentAccount;
  }

  public setCurrentAccountById(accountId: string) {
    this.accountService.getCrmAccountById(accountId).subscribe({
      next: foundAccount => {
        if (foundAccount) {
          this.setCurrentAccount(foundAccount);
        } else {
          this.setCurrentAccount(null);
        }
      },
      error: error => {
        console.error(error);
        this.setCurrentAccount(null);
      }
    });
  }

  public getCurrentAccount(): Observable<CrmAccount> {
    return this.currentAccount;
  }

  public getCurrentAccountObject(): CrmAccount {
    return this.currentAccountObject;
  }

  // This method can be called a couple of different ways
  // *hasClaim="'claimType'"  // Assumes claimValue is true
  // *hasClaim="'claimType:value'"  // Compares claimValue to value
  // *hasClaim="['claimType1','claimType2:value','claimType3']"
  hasClaim(claimType: string | string[], claimValue?: string) {
    let ret = false;

    // See if an array of values was passed in.
    if (typeof claimType === "string") {
      ret = this.isClaimValid(claimType, claimValue);
    }
    else {
      const claims = claimType;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }
    return ret;
  }

  hasSelectedContext(): boolean {
    return !!this.currentAccount;
  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret = true;
    let auth: AccountInfo = null;

    // Retrieve security object
    auth = this.b2cUser;
    if (auth) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(":") >= 0) {
        const words: string[] = claimType.split(":");
        claimType = words[0].toLowerCase();
        claimValue = words[1];
      }
      else {
        claimType = claimType.toLowerCase();
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : "true";
      }

      // Attempt to find the claim
      if (claimType) {
        ret = auth.idTokenClaims[claimType] === claimValue;
      }
      else {
        ret = !!auth.idTokenClaims[claimType];
      }
    }

    return ret;
  }

}
