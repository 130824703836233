<div *ngIf="card" class="container my-3 trend-card"
    [style]="{'color': card.textColor, 'background-color': card.backgroundColor}">
    <div class="row row-cols-1 row-cols-xl-2 gx-3" [ngClass]="{
            'flex-row': card.layout === 'ImagesLeft',
            'flex-row-reverse': card.layout === 'ImagesRight'}
        ">
        <div class="col align-self-center">
            <div class="row my-3 mx-0 gx-3 equal-height" [ngClass]="{
                    'row-cols-1': card.imageCount === 1,
                    'row-cols-2': card.imageCount === 2,
                    'row-cols-3': card.imageCount === 3}
                ">
                <div class="col" *ngFor="let i of GetImageCountArray()">
                    <img class="w-100" *ngIf="card.imageUrls[i]" [src]="card.imageUrls[i]" />
                    <img class="w-100" *ngIf="!card.imageUrls[i]" src="assets/images/noimage.png" />
                </div>
            </div>
        </div>
        <div class="col align-self-center">
            <div class="p-3 pb-4 pt-0 py-xl-5 pe-xl-4 d-flex flex-column justify-content-center">
                <h3>{{card.title}}</h3>
                <p>{{card.description}}</p>
                <a [href]="card.link" target="_blank" rel="noopener"
                    [style]="{'color': card.textColor, 'fill': card.textColor}">{{card.linkText}}<svg width="17"
                        height="18" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.75611 5.08753C5.58781 4.91922 5.58781 4.64634 5.75611 4.47804C5.92442 4.30973 6.1973 4.30973 6.36561 4.47804L10.6754 8.7878C10.8437 8.95611 10.8437 9.22899 10.6754 9.39729L6.36561 13.7071C6.1973 13.8754 5.92442 13.8754 5.75611 13.7071C5.58781 13.5388 5.58781 13.2659 5.75611 13.0976L9.76113 9.09255L5.75611 5.08753Z"
                            fill="currentColor" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</div>