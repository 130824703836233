import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ODataResponse } from '../../models/odata-response';
import { UserSettings } from '../../models/user-settings';
import { KendoAlertService } from '../kendo-alerts.service';
import { IUserSettingsService } from './user-settings.service.interface';

@Injectable({
    providedIn: 'root'
})

export class UserSettingsService implements IUserSettingsService {
    public baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private kendoAlertService: KendoAlertService
    ) {
        this.baseUrl = environment.apiBaseUrl + "portalusersettings";
    }

    public create(userSettings: UserSettings): Observable<unknown> {
        const url = this.baseUrl;
        return this.httpClient.post(url, userSettings);
    }
    public update(userSettings: UserSettings): Observable<unknown> {
        const url = this.baseUrl + "/update";
        return this.httpClient.patch(url, userSettings);
    }

    public get(userb2cId: string): Observable<UserSettings | undefined> {
        const url = this.baseUrl
            + `?$top=1`
            + `&$filter=(userb2cid eq '${userb2cId}')`;

        return this.httpClient.get<ODataResponse<UserSettings[]>>(url).pipe(
            map(response => {
                if (response && response.value && response.value[0]) {
                    const settings = response.value[0];
                    return settings;
                }
                return undefined;
            }),
            catchError(error => {
                this.kendoAlertService.showErrorAlert(error);
                return of(undefined);
            })
        );
    }
}
