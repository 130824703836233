import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { InvoiceHeader } from 'src/app/shared/models/invoices/invoice-header';
import { ODataResponse } from 'src/app/shared/models/odata-response';

@Injectable()
export abstract class IInvoiceService {
    baseUrl: string;

    abstract getInvoicesWithGridState(gridState: State, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<InvoiceHeader[]>>;
    abstract getInvoiceDetail(invoiceId: string, bkInvoiceAccountKey: string): Observable<InvoiceHeader>;
}
