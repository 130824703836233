import { InvoiceHeader } from "../invoices/invoice-header";
import { PortalOrder } from "../order-creation/portal-order";
import { OrderHeader } from "../orders/order-header";
import { CustomerTask } from "../task";
import { CategorySum } from "./category-sum";

export class GraphData {

    public GetGraphDataForInvoices(invoices: InvoiceHeader[]) {
        if (!invoices || invoices.length == 0) {
            return [];
        }

        const categorizedHeaders = this.createCategoryEntriesInvoice(invoices);
        return this.CreateCategories(categorizedHeaders);
    }

    public GetGraphDataForTasks(tasks: CustomerTask[]) {
        if (!tasks || tasks.length == 0) {
            return [];
        }

        const categorizedHeaders = this.createCategoryEntriesTask(tasks);
        return this.CreateCategories(categorizedHeaders);
    }

    public GetGraphDataForOrders(orders: OrderHeader[]): CategorySum[] {
        if (!orders || orders.length == 0) {
            return [];
        }

        const categorizedHeaders = this.createCategoryEntriesOrder(orders);
        return this.CreateCategories(categorizedHeaders);
    }

    public GetGraphDataForNewOrders(orders: PortalOrder[]): CategorySum[] {
        if (!orders || orders.length == 0) {
            return [];
        }

        const categorizedHeaders = this.createCategoryEntriesNewOrder(orders);
        return this.CreateCategories(categorizedHeaders);
    }


    private CreateCategories(categorizedHeaders: headerEntry[]) {
        if (!categorizedHeaders) {
            return [];
        }

        let categories: CategorySum[] = [];
        categorizedHeaders.forEach(catHeader => {
            let summary = categories.find(x => x.category == catHeader.categoryName);
            if (summary && catHeader.id) {
                summary.headerIds.push(catHeader.id);
                if (catHeader.value) {
                    summary.sum += catHeader.value;
                }
            }
            else {
                summary = {
                    category: catHeader.categoryName,
                    sum: catHeader.value,
                    headerIds: [catHeader.id]
                };
                categories.push(summary);
            }
        });
        categories = categories.sort((a, b) => {
            const aMonth = +a.category.split('/')[0];
            const bMonth = +b.category.split('/')[0];
            return aMonth > bMonth ? 1 : -1;
        });
        return categories;
    }

    private createCategoryEntriesInvoice(invoices: InvoiceHeader[]): headerEntry[] {
        if (!invoices) {
            return [];
        }
        return invoices.map(x => {
            let category = "other";
            if (x.InvoiceDate) {
                category = (x.InvoiceDate?.getMonth() + 1)?.toString() + '/' + x.InvoiceDate?.getFullYear()?.toString();
            }
            const TotalAmount = x.TotalAmount;
            const orderHeader = x;

            return {
                categoryName: category,
                value: TotalAmount,
                id: orderHeader.Id
            } as headerEntry;
        });
    }

    private createCategoryEntriesTask(data: CustomerTask[]): headerEntry[] {
        if (!data) {
            return [];
        }
        return data.map(x => {
            let category = "other";
            if (x.tikk_due) {
                category = (x.tikk_due?.getMonth() + 1)?.toString() + '/' + x.tikk_due?.getFullYear()?.toString();
            }
            return {
                categoryName: category,
                value: x.tikk_durationminutes,
                id: x.tikk_id
            } as headerEntry;
        });
    }

    private createCategoryEntriesOrder(orders: OrderHeader[]): headerEntry[] {
        if (!orders) {
            return [];
        }
        return orders.map(x => {
            let category = "other";
            if (x.OrderCreationTime) {
                category = (x.bkOrderDateKey.getMonth() + 1).toString() + '/' + x.bkOrderDateKey.getFullYear().toString();
            }
            const totalNetAmount = x.TotalAmount;
            const orderHeader = x;
            return {
                categoryName: category,
                value: totalNetAmount,
                id: orderHeader.Id
            } as headerEntry;
        });
    }
    private createCategoryEntriesNewOrder(orders: PortalOrder[]): headerEntry[] {
        if (!orders) {
            return [];
        }
        return orders.map(x => {
            let category = "other";
            if (x.requestedDeliveryDate) {
                category = (x.requestedDeliveryDate.getMonth() + 1).toString() + '/' + x.requestedDeliveryDate.getFullYear().toString();
            }
            const totalNetAmount = x.totalValue;
            const orderHeader = x;
            return {
                categoryName: category,
                value: totalNetAmount,
                id: orderHeader.id
            } as headerEntry;
        });
    }
}


interface headerEntry {
    categoryName: string,
    id: string;
    value: number,
}
