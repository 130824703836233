export const AgentSalesSalesDistricts = [
    "SE1015",
    "SE1025",
    "SE1035",
    "SE1045",
    "SE2015",
    "SE2025",
    "SE2035",
    "SE2045",
]
