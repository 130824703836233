import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { DropDownButtonComponent } from '@progress/kendo-angular-buttons';
import { SVGIcon, userIcon } from '@progress/kendo-svg-icons';
import { UserSettings } from 'src/app/shared/models/user-settings';
import { SecurityService } from 'src/app/shared/security/security.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { CustomLanguageService } from 'src/app/shared/services/language.service';
import { IUserSettingsService } from 'src/app/shared/services/user/user-settings.service.interface';
import { UserSettingsStore } from 'src/app/shared/stores/user-settings.store';
import { v4 as Guid } from 'uuid';
import { NavigationOption } from "../navigation-menu/navigation-option";

@Component({
  selector: 'ntw-user-option-chooser',
  templateUrl: './user-option-chooser.component.html',
  styleUrls: ['./user-option-chooser.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserOptionChooserComponent {
  @ViewChild('languageUserOptions') public languageUserOptionsDropdown: DropDownButtonComponent;

  public icons = {
    user: userIcon
  }

  constructor(private securityService: SecurityService,
    private authService: MsalService,
    private languageService: CustomLanguageService,
    private kendoAlertService: KendoAlertService,
    private userSettingsService: IUserSettingsService,
    private userSettingsStore: UserSettingsStore
    ) { }

  getIcon(option: NavigationOption): SVGIcon {
    return option.icon;
  }

  data =
    [{
      label: 'navigationTopBar.language', action: (event: Event) => { this.languageUserOptionsDropdown.toggle(this.languageUserOptionsDropdown.isOpen); event.stopPropagation(); },
      arr: [
        { label: 'navigationTopBar.languageEnglish', action: () => { this.changeLanguage('en-US'); } },
        { label: 'navigationTopBar.languageNorwegian', action: () => { this.changeLanguage('no'); } },
        { label: 'navigationTopBar.languagePolish', action: () => { this.changeLanguage('pl-PL'); } },
        //{ label: 'navigationTopBar.languageRussian', action: () => { this.changeLanguage('ru'); } },
        { label: 'navigationTopBar.languageSwedish', action: () => { this.changeLanguage('sv'); } },
      ]
    },
    { label: 'navigationTopBar.logout', action: () => { this.logOut(); } }];

  isArray(input): boolean {
    return Array.isArray(input.arr);
  }

  onUserOptionClick(event: Event, dataItem): void {
    dataItem.action(event);
  }

  logOut(): void {
    this.securityService.reset();
    this.authService.logout();
  }

  changeLanguage(languageCode: string) {
    if (this.languageService.currentLanguage == languageCode) {
      return;
    }
    if (this.userSettingsExist()) {
      this.updateUserSettings(languageCode);
    }
    else {
      this.createOrUpdateUserSettings(languageCode);
    }
    this.languageService.currentLanguage = languageCode;
  }

  private userSettingsExist() {
    return this.userSettingsStore.isLoaded();
  }

  private updateUserSettings(languageCode: string) {
    const userSettings = this.userSettingsStore.get().getValue();
    if (userSettings && userSettings.languageCode == languageCode) {
      return;
    }
    userSettings.languageCode = languageCode;
    this.userSettingsService.update(userSettings).subscribe({
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
  }

  private createOrUpdateUserSettings(languageCode: string) {
    if (!this.securityService.getUser()?.localAccountId) {
      return;
    }
    this.userSettingsService.get(this.securityService.getUser().localAccountId).subscribe(settings => {
      if (settings !== undefined) {
        this.updateUserSettings(languageCode);
      }
      else {
        const newSettings: UserSettings = {
          userb2cId: this.securityService.getUser().localAccountId,
          languageCode: languageCode,
          logicalName: "UserSettings",
          id: Guid()
        }
        this.userSettingsService.create(newSettings).subscribe({
          error: error => {
            this.kendoAlertService.showErrorAlert(error);
          }
        });
      }
    });
  }
}
