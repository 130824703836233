export class ColorShadeValue {
    odatatype: string;
    EAN: string;
    Families: FamilyKeyValue[];
    Ranges: RangeKeyValue[];
    Shades: ColorShade[];
}

export class FamilyKeyValue {
    Key: string;
    Value: string;
}

export class RangeKeyValue {
    Key: string;
    Value: string;
}

export class Colorant {
    CNTID: string
    CNTCode: string;
}

export class ColorShade {

    constructor(object) {
        if (object["name"]) {
            this.Name = object["name"];
        }
        if (object["code"]) {
            this.Code = object["code"]
        }
        if (object["canSize"]) {
            this.CanSize = object["canSize"];
        }
        if (object["rgbValue"]) {
            this.RGBValue = object["rgbValue"];
        }
        if (object["colorants"]) {
            this.Colorants = object["colorants"];
            this.Colorants.forEach(colorant => {
                colorant.CNTID = colorant["cntid"];
                colorant.CNTCode = colorant["cntCode"];
            });
        }
        if (object["basePaintCode"]) {
            this.BasePaintCode = object["basePaintCode"];
        }
    }

    Name: string;
    Code: string;
    RGBValue: string;
    CanSize: string;
    Families: string[];
    Ranges: string[];
    Colorants: Colorant[];
    BasePaintCode: string;
}
