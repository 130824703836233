import { PriceListResponseItem } from "../pricelists/pricelist-response";
import { Product } from "../products/product";

export class ProductWithInventoryAndQuantity extends Product {
    Inventory: number;
    InventoryLoading: boolean = false;
    InventoryNotAvailable: boolean = false;
    Quantity: number;
    SalesPrice: number;
    SalesPriceLoading: boolean = false;
    SalesPriceNotAvailable: boolean = false;
    UnitNetPrice: number;
    UnitNetPriceLoading: boolean = false;
    UnitNetPriceNotAvailable: boolean = false;

    public static calculateProductPrice(product: ProductWithInventoryAndQuantity, priceItem: PriceListResponseItem) {
        let totalDiscount = 0;
        if (priceItem.discAmt > 0) {
            totalDiscount = priceItem.discAmt;
        }
        if (priceItem.discPct > 0) {
            totalDiscount = priceItem.price * priceItem.discPct / 100;
        }
        product.SalesPrice = priceItem.price;
        product.UnitNetPrice = priceItem.price - totalDiscount;
    }
}
