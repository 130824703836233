import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ICustomerService } from '../../services/customer/customer.service.interface';
import { SecurityService } from "../security.service";
import { ContextGuardService } from './context-guard.service';

export const createOrdersGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state,
  contextGuardService: ContextGuardService = inject(ContextGuardService),
  customerService: ICustomerService = inject(ICustomerService),
  securityService: SecurityService = inject(SecurityService),
  router: Router = inject(Router)
) => {
  return new Observable<boolean | UrlTree>(obs => {
    contextGuardService.canActivate(route).subscribe(contextGuardResult => {
      if (!contextGuardResult) {
        obs.next(false);
      }

      securityService.getCurrentAccount().subscribe(account => {
        if (!account) {
          obs.next(router.parseUrl('/'));
        }

        if (!securityService.canCreateOrders()) {
          obs.next(router.parseUrl(`/account/${account.accountid}`));
        }

        customerService.isAgentSalesCustomer(account.ntw_axcode).subscribe(isAgentSalesCustomer => {
          if (isAgentSalesCustomer) {
            obs.next(router.parseUrl(`/account/${account.accountid}`));
          } else {
            obs.next(true);
          }
        });
      });
    });
  });
}
