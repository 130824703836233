import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { SharedModule } from '../shared/shared.module';
import { IInvoiceService } from './services/invoice.service.interface';
import { InvoiceService } from './services/invoice.service';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';

@NgModule({
  declarations: [
    InvoiceDetailComponent,
    InvoiceListComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    { provide: IInvoiceService, useClass: InvoiceService }
  ]
})

export class InvoiceModule { }
