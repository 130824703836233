import { NewOrderAddress } from "../address/address";
import { Warehouse } from "../warehouse";
import { PortalOrderProduct } from "./portal-order-product";

export interface PortalOrderBase {
    id: string;
    portalOrderId: string;
    createdOn: Date;
    modifiedOn: Date;
    submittedOn: Date;
    customerReference: string;
    customerRequisition: string;
    ownerUserb2cId: string;
    bkCustomerNumber: string;
    bkCompanyNumber: string;
    customerGLNNumber: string;
    companyGLNNumber: string;
    warehouse: Warehouse;
    isWarehousePickUpPoint: boolean;
    orderProducts: PortalOrderProduct[];
    deliveryAddress: NewOrderAddress;
    requestedDeliveryDate: Date;
    backOrdersPossible: boolean;
    comments: string;
    LogicalName: "PortalOrder";
    orderSubmissionStatus: PortalOrderStatus;
}

export interface PortalOrder extends PortalOrderBase {
    orderNumber: string;
    totalValue: number;
    totalValueWithVat: number;
    totalVat: number;
    totalProducts: number;
    totalNetWeight: number;
    totalGrossWeight: number;
    totalVolumeLiters: number;
    totalPalletQty: number;
    orderSubmissionStatusName: string;
}

export enum PortalOrderStatus {
    Draft = 0,
    Submitted = 1,
    PlacedInAx = 2
}
