import { Component, Input } from '@angular/core';
import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';

@Component({
    selector: 'ntw-loading-panel',
    styles: [`
        .fs {
            z-index: 100000;
        }
        .loading-panel {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
        }
        .loading-panel-background {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #656565;
          opacity: 0.4;
        }
        .loading-panel-wrapper {
          position: relative;
          z-index: 1001;
        }
    `],
    template: `
        <div class="loading-panel" *ngIf="this.fullscreen" [ngClass]="{'fs': this.coverTopBar }">
            <div class="loading-panel-background"></div>
            <div class="loading-panel-wrapper">
                <kendo-loader [type]="type" [themeColor]="themeColor" [size]="size">
                </kendo-loader>
            </div>
        </div>
        <div  class="d-flex justify-content-center align-items-center h-100" *ngIf="!this.fullscreen">
            <kendo-loader [type]="type" [themeColor]="themeColor" [size]="size"></kendo-loader>
        </div>
    `
})

export class LoadingPanelComponent {
    @Input() public type: LoaderType = "converging-spinner";
    @Input() public themeColor: LoaderThemeColor = "light";
    @Input() public size: LoaderSize = "large";
    @Input() public fullscreen: boolean = true;
    @Input() public coverTopBar: boolean = false;
}
