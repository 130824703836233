import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, iif, mergeMap, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PIMProductsResponse } from '../../models/pimproducts/pimproducts-response';
import { KendoAlertService } from '../kendo-alerts.service';

@Injectable({
  providedIn: 'root'
})
export class PimproductsService {
  private _baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private kendoALertService: KendoAlertService
  ) {
    this._baseUrl = environment.apiBaseUrl + `pim/products`;
  }

  public get(id: string) {
    const url = this._baseUrl
      + `?id=${id}`;

    return this.httpClient.get<PIMProductsResponse>(url).pipe(
      mergeMap(response => iif(() => response?.results?.length === 1, of(response?.results?.at(0)), of(null))),
      catchError(error => {
        this.kendoALertService.showErrorAlert(error);
        throw new Error(error);
      })
    );
  }
}
