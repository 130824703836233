import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { GridNames } from '../../constants/grid-names';

@Injectable({
  providedIn: 'root'
})
export class GridPersistService {

  public SaveGridState(gridName: string, state: State) {
    sessionStorage.setItem(gridName, JSON.stringify(state));
  }

  public ResetGridState() {
    sessionStorage.removeItem(GridNames.invoiceGrid);
    sessionStorage.removeItem(GridNames.orderGrid);
    sessionStorage.removeItem(GridNames.portalOrderGrid);
    sessionStorage.removeItem(GridNames.productsGrid);
  }

  public GetGridState(gridName: string, currentState: State): State {
    const stateJson = sessionStorage.getItem(gridName);
    if (!stateJson) {
      return currentState;
    }
    const savedState = JSON.parse(stateJson, this.ReviveDateTime) as State;
    if (savedState) {
      return savedState;
    }
    return currentState;
  }

  private ReviveDateTime(key: unknown, value: unknown): unknown {
    if (typeof value === 'string' && !(+value)) {
      const date = Date.parse(value);
      if (date) {
        const parsedDate = new Date(date);
        //a precaution against dates created from random string with a number within it.
        if (parsedDate.getFullYear() > 2001) {
          return new Date(date);
        }
      }
    }
    return value;
  }
}
