import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { ISkuFilterData } from './sku-filter-data';

interface ISkuFilterBreadcrumbItem extends BreadCrumbItem {
    code: string;
}

@Component({
    selector: 'ntw-sku-filter-breadcrumbs',
    template: `
    <kendo-breadcrumb *ngIf="this.items && this.items.length > 0"
        (itemClick)="onItemClick($event)" [items]="breadCrumbItems">
    </kendo-breadcrumb>
  `
})
export class SKUFilterBreadCrumbsComponent implements OnChanges {
    @Input() public items: ISkuFilterData[] = [];
    @Output() public itemClick = new EventEmitter<ISkuFilterData>();

    breadCrumbItems: ISkuFilterBreadcrumbItem[] = [];

    public onItemClick(item: BreadCrumbItem): void {
        const selectedItem = this.items.find(i => i.code === (item as ISkuFilterBreadcrumbItem).code);
        this.itemClick.emit(selectedItem);
    }

    //* If the mapping is done by a function instead of ngOnChanges, there is an infinite loop triggering re-rendering
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            this.breadCrumbItems = this.items.map(item => {
                return {
                    code: item.code,
                    text: item.text,
                    title: item.text,
                };
            });
        }
    }
}
