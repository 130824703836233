import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrmAccount } from 'src/app/shared/models/account';

@Injectable()
export abstract class IAccountService {
    abstract getAccounts(): Observable<CrmAccount[]>;
    abstract getChildAccounts(guid: string): Observable<CrmAccount[]>;
    abstract getCrmAccountById(accountId: string): Observable<CrmAccount>
}
