import { Injectable } from '@angular/core';
import { concatMap, map, Observable, skipWhile } from 'rxjs';
import { Warehouse } from '../../models/warehouse';
import { CompanyAdminConfigurationStore } from '../../stores/company-admin-configuration.store';
import { ICustomerService } from '../customer/customer.service.interface';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private customerService: ICustomerService
  ) {}

  public get(): Observable<Warehouse[]> {
    return this.companyAdminConfigurationStore.get().pipe(skipWhile(x => !x), concatMap(companyAdminConfiguration => {

      return this.customerService.getCompanyByCustomerRegisterCode(companyAdminConfiguration.bkCompanyKey).pipe(map(company => {
        if (!company?.site || company.site.length === 0 || company.site.filter(s => !s.warehouse || s.warehouse.length === 0).length !== 0) {
          throw new Error('alerts.noAvailableWarehousesFound');
        }

        const warehouses: Warehouse[] = [];

        company.site.forEach(site => {
          site.warehouse.forEach(warehouse => {
            warehouses.push(warehouse);
          })
        });

        return warehouses.filter(w => {
          const warehouseConfig = companyAdminConfiguration.warehouseConfigurations.find(wc => wc.bkWarehouseKey === w.bkWarehouseKey);
          return warehouseConfig && warehouseConfig.visibleInSalesOrder;
        });

      }));

    }));
  }
}
