import { FieldConfiguration } from "./field-configuration";
import { ColumnSettings } from "./ui/column-settings";
import { TitleValue } from "./ui/title-value";

export class FormConfiguration {
  name: string;
  fieldConfigurations: FieldConfiguration[];

  static applyToColumnSettings(formConfig: FormConfiguration, columnSettings: ColumnSettings[]): ColumnSettings[] {
    return columnSettings.filter(cs => this.getFieldVisibility(formConfig, cs.field));
  }

  static applyToTitleValues(formConfig: FormConfiguration, titleValues: TitleValue[]): TitleValue[] {
    return titleValues.filter(tv => tv.field == undefined || this.getFieldVisibility(formConfig, tv.field));
  }

  static getFieldVisibility(formConfig: FormConfiguration, fieldName: string): boolean {
    const fieldConfig = formConfig.fieldConfigurations?.find(x => x.name === fieldName);

    return fieldConfig?.visible ?? true;
  }
}
