export class ODataResponse<T> {

  constructor(response: ODataResponse<T>) {
    this.value = response.value;
    if (response["@odata.context"]) {
      this.context = response["@odata.context"];
    }
    if (response["@odata.count"]) {
      this.count = response["@odata.count"];
    }
    else {
      const array = response.value;
      if (array) {
        this.count = array['length'] ?? 0;
      }
    }

  }

  context: string;
  count: number;
  value: T;
}
