export interface ISkuFilterData {
    parentCode?: string;
    code: string;
    level?: number;
    text: string;
    items?: ISkuFilterData[];
}

export const skuFilterData: ISkuFilterData[] = [
    {
        code: "1.",
        level: 0,
        text: "sku-filter.1-interiorpaints",
    },
    {
        parentCode: "1.",
        code: "1.1.",
        level: 1,
        text: "sku-filter.1-1-wallsandceilings",
    },
    {
        parentCode: "1.",
        code: "1.2.",
        level: 1,
        text: "sku-filter.1-2-floors",
    },
    {
        parentCode: "1.",
        code: "1.3.",
        level: 1,
        text: "sku-filter.1-3-furnitureandtrim",
    },
    {
        parentCode: "1.",
        code: "1.4.",
        level: 1,
        text: "sku-filter.1-4-effects",
    },
    {
        parentCode: "1.",
        code: "1.5.",
        level: 1,
        text: "sku-filter.1-5-others",
    },
    {
        code: "2.",
        level: 0,
        text: "sku-filter.2-exteriorpaints",
    },
    {
        parentCode: "2.",
        code: "2.1.",
        level: 1,
        text: "sku-filter.2-1-wood",
    },
    {
        parentCode: "2.",
        code: "2.2.",
        level: 1,
        text: "sku-filter.2-2-mineral",
    },
    {
        parentCode: "2.",
        code: "2.3.",
        level: 1,
        text: "sku-filter.2-3-metal",
    },
    {
        code: "3.",
        level: 0,
        text: "sku-filter.3-otherpaints",
    },
    {
        parentCode: "3.",
        code: "3.1.",
        level: 1,
        text: "sku-filter.3-1-universalproducts",
    },
    {
        parentCode: "3.",
        code: "3.2.",
        level: 1,
        text: "sku-filter.3-2-cleaningagents",
    },
    {
        parentCode: "3.",
        code: "3.4.",
        level: 1,
        text: "sku-filter.3-4-glues",
    },
    {
        parentCode: "3.",
        code: "3.5.",
        level: 1,
        text: "sku-filter.3-5-fillers",
    },
    {
        code: "5.",
        level: 0,
        text: "sku-filter.5-industrialpaints",
    },
    {
        code: "6.",
        level: 0,
        text: "sku-filter.6-supportproducts",
    },
    {
        parentCode: "6.",
        code: "6.4.",
        level: 1,
        text: "sku-filter.6-4-marketingmaterials",
    },
    {
        parentCode: "6.",
        code: "6.5.",
        level: 1,
        text: "sku-filter.6-5-tintingequipment",
    },
    {
        parentCode: "6.",
        code: "6.8.",
        level: 1,
        text: "sku-filter.6-8-materialsales",
    },
    {
        code: "7.",
        level: 0,
        text: "sku-filter.7-specialitems",
    },
];
