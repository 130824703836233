import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskListComponent } from './task-list/task-list.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { ITaskService } from './services/task.service.interface';
import { TaskService } from './services/task.service';
import { TaskDetailsComponent } from './task-details/task-details.component';


@NgModule({
  declarations: [TaskListComponent, TaskDetailsComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    { provide: ITaskService, useClass: TaskService }
  ]
})
export class TaskModule { }
