<kendo-dialog
  title="{{ 'account.yourWarehouse' | translate }}"
  (close)="close()"
  [minWidth]="400"
  [minHeight]="200"
  [maxWidth]="1320"
  maxHeight="90vh"
>
    <ntw-loading-panel *ngIf="loading" [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
    <div class="h-100 d-flex align-items-center" *ngIf="!loading">
        <ntw-loading-dropdownlist class="w-100 px-2" [loading]="!warehouses || warehouses.length === 0"
            [defaultItem]="{ warehouseName: 'account.selectWarehouse' | translate }"
            [data]="warehouses" (valueChange)="selectedChanged($event)"
            textField="warehouseName" valueField="bkWarehouseKey">
        </ntw-loading-dropdownlist>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="close()">{{ 'shared.ok' | translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>