import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationRef, NotificationService, NotificationSettings } from '@progress/kendo-angular-notification';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KendoAlertService {

  constructor(private notificationService: NotificationService,
    private translateService: TranslateService) {
  }

  private sharedErrorGuids = [];

  public showErrorAlert(error: unknown, autoClose = false, autoCloseAfter = undefined): NotificationRef {
    console.error(error);
    if (error['sharedErrorGuid']) {
      if (this.sharedErrorGuids.includes(error['sharedErrorGuid'])) {
        return null;
      }
      this.sharedErrorGuids.push(error['sharedErrorGuid']);
      setTimeout(() => {
        const index = this.sharedErrorGuids.indexOf(error['sharedErrorGuid']);
        if (index > -1) {
          this.sharedErrorGuids.splice(index, 1);
        }
      }, 5000);
    }
    return this.notificationService.show(this.getDefaultSettings(this.getErrorMessage(error), autoClose, autoCloseAfter, 'error'));
  }

  public showInfoAlert(info: string, autoClose = false, autoCloseAfter = undefined): NotificationRef {
    info = this.translateService.instant(info);
    // eslint-disable-next-line no-console
    console.info(info);
    return this.notificationService.show(this.getDefaultSettings(info, autoClose, autoCloseAfter, 'info'));
  }

  public showWarningAlert(warning: string, autoClose = false, autoCloseAfter = undefined): NotificationRef {
    warning = this.translateService.instant(warning);
    console.warn(warning);
    return this.notificationService.show(this.getDefaultSettings(warning, autoClose, autoCloseAfter, 'warning'));
  }

  public showSuccessAlert(message: string, autoClose = true, autoCloseAfter = undefined): NotificationRef {
    message = this.translateService.instant(message);
    return this.notificationService.show(this.getDefaultSettings(message, autoClose, autoCloseAfter, 'success'));
  }

  private getErrorMessage(error: unknown): string {
    let message = "alerts.unknownError";
    if (!error) {
      return this.translateService.instant(message);
    }

    if (typeof error === 'string') {
      return this.translateService.instant(error as string);
    }

    if (error instanceof HttpErrorResponse) {
      const endpoint = error.url.replace(environment.apiBaseUrl, '').split('?')[0];
      message = this.translateService.instant('alerts.unexpectedError')
      message += ' HTTP Error: ' + error.status + ' - ' + error.statusText + ' - ' + endpoint;
      if (error.error && typeof error.error === 'string') {
        message += ' - ' + error.error;
      }
      return message;
    }

    if (error['error'] && typeof error['error'] === 'string') {
      message = error['error'];
    }
    else if (error['message'] && typeof error['message'] === 'string') {
      message = error['message'];
    }
    else if (error['error']
          && typeof error['error'] === 'object'
          && error['error']['message']
          && typeof error['error']['message'] === 'string') {
      message = error['error']['message'];
    }

    if (message === 'alerts.unknownError') {
      return this.translateService.instant(message);
    }

    return message;
  }

  private getDefaultSettings(content: string, autoClose: boolean, autoCloseAfter?: number,
    style?: 'none' | 'success' | 'warning' | 'error' | 'info',): NotificationSettings {
    const settings: NotificationSettings = {
      content: content,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: style, icon: true },
      hideAfter: autoCloseAfter ?? 3000,
      closable: !autoClose,
      width: 400,
    }
    return settings;
  }

}
