import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { finalize, map, share } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Company } from "../../models/company";
import { CosmosCustomer } from "../../models/customer";
import { ODataResponse } from "../../models/odata-response";
import { ICustomerService } from "./customer.service.interface";
import { AgentSalesSalesDistricts } from "../../constants/agent-sales-sales-districts";

@Injectable({
    providedIn: 'root'
})
export class CustomerService implements ICustomerService {
    private baseUrl = "";
    private currentCustomer: CosmosCustomer = null;
    private currentCompany: Company = null;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl;
    }

    private customer$: Observable<CosmosCustomer>;

    public getCustomerByCode(axCode: string): Observable<CosmosCustomer> {
        if (this.currentCustomer && this.currentCustomer?.AXCode == axCode) {
            return of(this.currentCustomer);
        }

        if (this.customer$) {
            return this.customer$;
        }

        const url = this.baseUrl + "customers" + `?$filter=AXCode eq '${axCode}'&$top=1`;
        this.customer$ = this.httpClient.get<ODataResponse<CosmosCustomer[]>>(url).pipe(
            map(response => {
                if (response && response && response.value[0]) {
                    this.currentCustomer = response.value[0];
                }
                return this.currentCustomer;
            }),
            share(),
            finalize(() => this.customer$ = null)
        );

        return this.customer$;
    }

    private company$: Observable<Company>;

    public getCompanyByCustomerRegisterCode(companyRegisterCode: string): Observable<Company> {
        if (this.currentCompany && this.currentCompany?.bkCompanyKey == companyRegisterCode) {
            return of(this.currentCompany);
        }

        if (this.company$) {
            return this.company$;
        }

        const url = this.baseUrl + `companydatacosmos?$top=1&$filter=bkCompanyKey eq '` + companyRegisterCode + `'`;

        this.company$ = this.httpClient.get<ODataResponse<Company>>(url).pipe(map(response => {
            if (!response || !response.value || !response.value[0]) {
                throw new Error('alerts.noCompanyForCustomerFound');
            }
            this.currentCompany = response.value[0];
            return response.value[0] as Company;
        }),
        share(),
        finalize(() => this.company$ = null));

        return this.company$;
    }

    public isAgentSalesCustomer(axCode: string): Observable<boolean> {
        return this.getCustomerByCode(axCode).pipe(map(customer => {
            return customer && AgentSalesSalesDistricts.includes(customer.SalesDistrict);
        }));
    }
}
