import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, mergeMap } from "rxjs";
import { environment } from "src/environments/environment";
import { CosmosCustomer } from "../../models/customer";
import { PortalOrderProduct } from "../../models/order-creation/portal-order-product";
import { ProductWithInventoryAndQuantity } from "../../models/order-creation/product-with-quantity";
import { PricelistMultiRequest } from "../../models/pricelists/pricelist-multi-request";
import { PriceListResponse } from "../../models/pricelists/pricelist-response";
import { PricelistSingleRequest } from "../../models/pricelists/pricelist-single-request";
import { Utils } from "../../utils";
import { IPricelistService } from "./pricelist.service.interface";


@Injectable({
    providedIn: 'root'
})

export class PricelistService implements IPricelistService {
    private baseUrl: string;
    private chunkSize = 25;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl;
    }

    getPriceForSingleProduct(product: PortalOrderProduct | ProductWithInventoryAndQuantity, customer: CosmosCustomer): Observable<PriceListResponse> {
        const request = new PricelistSingleRequest(product, customer);
        const url = this.baseUrl + "pricelists/getsingle";
        return this.httpClient.post<PriceListResponse>(url, request);
    }

    getPriceForMultipleProducts(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer): Observable<PriceListResponse> {
        // Split the products into chunks of 10
        const chunks = Utils.chunkArray<unknown>([...products], this.chunkSize);

        // Create an observable from the chunks array
        return from(chunks).pipe(
            mergeMap(chunk => {
                if (this.chunkSize === 1) {
                    return this.getPriceForSingleProduct(chunk[0] as PortalOrderProduct | ProductWithInventoryAndQuantity, customer);
                }
                return this.getPriceForMultipleProductsChunk(chunk as PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer);
            })
        );
    }

    private getPriceForMultipleProductsChunk(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer): Observable<PriceListResponse> {
        const request = new PricelistMultiRequest(products, customer);
        const url = this.baseUrl + "pricelists/getmultiple";
        return this.httpClient.post<PriceListResponse>(url, request);
    }
}
