import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Subject, concatMap, takeUntil } from 'rxjs';
import { GridNames } from 'src/app/shared/constants/grid-names';
import { StringConstants } from 'src/app/shared/constants/string-constants';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { InvoiceHeader } from 'src/app/shared/models/invoices/invoice-header';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from 'src/app/shared/services/filter-sort-description.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { GridPersistService } from 'src/app/shared/services/ui/grid-persist.service';
import { Utils } from 'src/app/shared/utils';
import { IInvoiceService } from '../services/invoice.service.interface';
import { invoiceHeaderColumns } from './invoice-header-columns';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { invoicesListLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';

@Component({
  selector: 'ntw-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent extends KendoGridBase<InvoiceHeader> implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public currentAccount: CrmAccount;
  private currentCustomer: CosmosCustomer;

  public totalValues: TitleValue[];
  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    drag: false,
    enabled: true,
  }

  columnSettings: ColumnSettings[];
  formConfig: FormConfiguration;

  constructor(
    private filterSortService: FilterAndSortDescriptionService,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private customerService: ICustomerService,
    private gridPersistService: GridPersistService,
    private invoiceService: IInvoiceService,
    private kendoAlertService: KendoAlertService,
    private securityService: SecurityService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.kendoGridState = this.gridPersistService.GetGridState(GridNames.invoiceGrid, this.kendoGridState);
    this.companyAdminConfigurationStore.getFormConfiguration(invoicesListLineColumnsFormName).pipe(concatMap(formConfig => {
      this.formConfig = formConfig;
      return this.securityService.getCurrentAccount();
    }), concatMap(currentAccount => {
      this.currentAccount = currentAccount;
      return this.customerService.getCustomerByCode(currentAccount.ntw_axcode);
    })).subscribe({
      next: customer => {
        this.currentCustomer = customer;
        this.columnSettings = FormConfiguration.applyToColumnSettings(this.formConfig, [...invoiceHeaderColumns]);
        this.setTotalValues();
        this.setDefaultFiltersAndSort();
        this.getEntitiesForAccount();
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error.error);
        this.loading = false;
      }
    });
    this.setupLanguageChangeHandler();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  override dataStateChange = (state: DataStateChangeEvent) => {
    this.kendoGridState = state;
    this.gridPersistService.SaveGridState(GridNames.invoiceGrid, state);
    this.getEntitiesForAccount();
  }

  getEntitiesForAccount() {
    if (!this.currentAccount?.ntw_axcode) {
      this.entities = [];
      return;
    }
    this.loading = true;
    this.invoiceService.getInvoicesWithGridState(this.kendoGridState, this.searchBarFilters).subscribe({
      next:
        odataResponse => {
          if (odataResponse && odataResponse.value) {
            this.generateGridDataViewOData(odataResponse);
          }
        }, error: error => {
          this.kendoAlertService.showErrorAlert(error.error);
          this.loading = false;
        }
    });
  }

  private setDefaultFiltersAndSort() {
    this.setBkAccountInvoiceFilter();
    this.setInvoiceDateDecreasingSorting();
  }

  setBkAccountInvoiceFilter() {
    const invoiceAccountAxCode = this.currentAccount?.ntw_axcode;
    const axCodeFilter = this.filterSortService.createFilter(StringConstants.BkInvoiceAccountKeyField, invoiceAccountAxCode, "eq");
    this.filterSortService.addFilter(this.kendoGridState, axCodeFilter);
  }

  private setInvoiceDateDecreasingSorting() {
    const invoiceDateSortDesc = this.filterSortService.createSortDescriptor('InvoiceDate', 'desc');
    this.filterSortService.setDefaultSortDescriptor(this.kendoGridState, invoiceDateSortDesc);
  }

  override refreshView(): void {
    this.getEntitiesForAccount();
  }

  generateSummary = (invoices: InvoiceHeader[]) => {
    this.summaries = [];
    this.summaries = this.graphData.GetGraphDataForInvoices(invoices);
    this.setTitleValues(invoices);
  }

  setTitleValues(invoices: InvoiceHeader[]) {
    let totalLineAmountFinal = 0;
    let totalLineAmountTaxFinal = 0;
    let totalOrderPriceFinal = 0;

    if (invoices.length == 0) {
      this.totalValues.forEach(x => {
        x.value = "shared.notApplicable";
        x.subtitle = "shared.norecordsselected";
      });
      return;
    }
    else if (invoices.length > 0) {
      this.totalValues.forEach(x => {
        x.subtitle = "shared.selectedrecords"
      });
    }

    invoices.forEach(element => {
      if (element.TotalAmount) {
        totalLineAmountFinal += element.TotalAmount;
      }
      if (element.TotalVAT) {
        totalLineAmountTaxFinal += element.TotalVAT;
      }
      if (element.TotalAmountInclVAT) {
        totalOrderPriceFinal += element.TotalAmountInclVAT;
      }
    });


    if (this.totalValues.length > 0) {
      this.totalValues[0].value = Utils.FormatNumber(totalLineAmountFinal, 2);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].value = Utils.FormatNumber(totalLineAmountTaxFinal, 2);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].value = Utils.FormatNumber(totalOrderPriceFinal, 2);
    }

    this.translateTitleValues();
  }

  private translateTitleValues(): void {
    if (this.totalValues.length > 0) {
      this.totalValues[0].title = Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalAmount"), this.currentCustomer);
    }
    if (this.totalValues.length > 1) {
      this.totalValues[1].title = Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalVAT"), this.currentCustomer);
    }
    if (this.totalValues.length > 2) {
      this.totalValues[2].title = Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalAmountInclVAT"), this.currentCustomer);
    }
  }

  override getQuickSearchFilters(query: string): FilterDescriptor[] {
    const filterArray = [];
    filterArray.push(this.filterSortService.createFilter("InvoiceNumber", query, "contains"));
    return filterArray;
  }

  private setTotalValues() {
    const totalValues: TitleValue[] = [
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalAmount"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalAmount"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalVAT"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalVAT"
      },
      {
        title: Utils.TryAddCurrencyCode(this.translateService.instant("invoice.TotalAmountInclVAT"), this.currentCustomer),
        value: "shared.notApplicable",
        subtitle: "shared.norecordsselected",
        field: "TotalAmountInclVAT"
      },
    ];

    this.totalValues = FormConfiguration.applyToTitleValues(this.formConfig, totalValues);
  }

  private setupLanguageChangeHandler() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.translateTitleValues();
    })
  }
}
