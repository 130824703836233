export interface DashboardCard {
    title: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    link: string;
    linkText: string;
    visibleFor: DashboardCardVisibleFor;
    layout: DashboardCardLayout;
    imageCount: DashboardCardImageCount;
    imageUrls: string[];
  }

export enum DashboardCardLayout {
    ImagesLeft = 'ImagesLeft',
    ImagesRight = 'ImagesRight',
}

export enum DashboardCardImageCount {
    One = 1,
    Two = 2,
    Three = 3,
}

export enum DashboardCardVisibleFor {
    None = 'None',
    All = 'All',
    Alcro = 'Alcro',
    Beckers = 'Beckers',
}
