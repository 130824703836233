import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerSettings } from '../../models/customer-settings';

@Injectable({
    providedIn: 'root'
})
export abstract class ICustomerSettingsService {
    public abstract get(bkCustomerKey: string): Observable<CustomerSettings | undefined>
    public abstract create(customerSettings: CustomerSettings): Observable<unknown>
    public abstract update(customerSettings: CustomerSettings): Observable<unknown>
}
