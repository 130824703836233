import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CosmosCustomer } from "../../models/customer";
import { InvoiceHeader } from "../../models/invoices/invoice-header";
import { OrderHeader } from "../../models/orders/order-header";
import { PrintInvoiceRequest, PrintInvoiceResponse } from "../../models/print/invoice";
import { PrintPackingSlipRequest, PrintPackingSlipResponse } from "../../models/print/packingslip";
import { IPrintService } from "./print.service.interface";
import { PrintAccountStatementRequest, PrintAccountStatementResponse } from "../../models/print/account-statement";

@Injectable({
    providedIn: 'root'
})

export class PrintService implements IPrintService {
    private baseUrl: string;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl;
    }

    public printAccountStatement(email: string, customer: CosmosCustomer): Observable<PrintAccountStatementResponse> {
        const request = new PrintAccountStatementRequest(email, customer);
        const url = this.baseUrl + "print/printstatement";
        return this.httpClient.post<PrintAccountStatementResponse>(url, request);
    }

    public printPackingSlip(order: OrderHeader, email: string): Observable<PrintPackingSlipResponse> {
        const request = new PrintPackingSlipRequest(order, email);
        const url = this.baseUrl + "print/packingslip";
        return this.httpClient.post<PrintPackingSlipResponse>(url, request);
    }

    public printInvoice(invoice: InvoiceHeader, email: string, customer: CosmosCustomer): Observable<PrintInvoiceResponse> {
        const request = new PrintInvoiceRequest(invoice, email, customer);
        const url = this.baseUrl + "print/invoice";
        return this.httpClient.post<PrintInvoiceResponse>(url, request);
    }
}
