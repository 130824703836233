import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CosmosAddress } from "../../models/address/address";
import { ODataResponse } from "../../models/odata-response";
import { IAddressService } from "./address.service.interface";


@Injectable({
    providedIn: 'root'
})

export class AddressService implements IAddressService {
    public baseUrl = "";

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl + "addresses";
    }

    public getAddresses(customerbkObjectKey: string): Observable<CosmosAddress[]> {
        const url = this.baseUrl
            + `?$top=100`
            + `&$filter=(bkObjectKey eq '${customerbkObjectKey}' and logicalType eq 'Customer')`;

        return this.httpClient.get<ODataResponse<CosmosAddress[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const address = response.value.filter(x => x.validTo > new Date());
                return address;
            }
            return null;
        }));
    }

    public getAddressById(addressRecId: string): Observable<CosmosAddress> {
        const url = this.baseUrl
            + `?$top=1`
            + `&$filter=(RecId eq ${addressRecId})`;

        return this.httpClient.get<ODataResponse<CosmosAddress[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const address = response.value[0];
                return address;
            }
            return null;
        }));
    }

    public getPrimaryAddressByCustomerKey(bkCustomerKey: string): Observable<CosmosAddress> {
        const url = this.baseUrl
            + `?$top=100`
            + `&$filter=(bkCustomerKey eq '${bkCustomerKey}' and isPrimary eq ${1})`;

        return this.httpClient.get<ODataResponse<CosmosAddress[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const address = response.value.filter(x => x.validTo > new Date())[0];
                return address;
            }
            return null;
        }));
    }

    public getInvoiceAddressByCustomerKey(bkCustomerKey: string): Observable<CosmosAddress> {
        const url = this.baseUrl
            + `?$top=100`
            + `&$filter=(bkCustomerKey eq '${bkCustomerKey}' and isRoleInvoice eq ${1})`;

        return this.httpClient.get<ODataResponse<CosmosAddress[]>>(url).pipe(map(response => {
            if (response && response.value && response.value[0]) {
                const address = response.value.filter(x => x.validTo > new Date())[0];
                return address;
            }
            return null;
        }));
    }
}
