export class Product {
    isChecked: boolean;
    bkProductKey: string;
    DataAreaID: string;
    ItemBuyerGroupID: string;
    CommisionGroupId: string;
    ReleasedDateKey: number;
    ReleasedDate: Date;
    OriginCountry: string;
    ItemGroupId: number;
    ItemGroupName: string;
    Stopped: number;
    Description: string;
    LineDisc: string;
    ProductID: number;
    SafetyCode: string;
    AltItemID: string;
    AXCode: string;
    AXLegacyCode: string;
    Name: string;
    EnglishName: string;
    Name_Ru: string;
    Name_Lt: string;
    Name_EnUs: string;
    Name_Et: string;
    Name_SvFi: string;
    Name_De: string;
    Name_Sv: string;
    Name_ZhHans: string;
    Name_Da: string;
    Name_Lv: string;
    Name_En: string;
    Name_NbNo: string;
    Name_Fi: string;
    Name_Pl: string;
    LegacyCode: string;
    LegacyID: string;
    BrandCode: string;
    BrandName: string;
    BrandPL: string;
    SubbrandCode: string;
    SubbrandName: string;
    GlossLevelCode: string;
    GlossLevelName: string;
    HasSaleslife: string;
    SkuClassificationCode1: string;
    SkuClassificationName1: string;
    SkuClassificationCode2: string;
    SkuClassificationName2: string;
    SkuClassificationCode3: string;
    SkuClassificationName3: string;
    SKUClassification1: string;
    SKUClassification2: string;
    SKUClassification3: string;
    MasterItemTypeCode: string;
    MasterItemTypeName: string;
    MasterPackingSizeCode: string;
    MasterPackingSizeName: string;
    MasterBasicUMCode: string;
    MasterBasicUMName: string;
    LegacyItemType: string;
    LegacyPackingSize: string;
    LegacyBasicUM: string;
    ProductClassCode: string;
    ProductClassName: string;
    Colors: number;
    TradeNameCode: string;
    TradeNameName: string;
    FamilyCode: string;
    FamilyName: string;
    MainBinderCode: string;
    MainBinderName: string;
    MainTargetGroupCode: string;
    MainTargetGroupName: string;
    PalletSizeCode: string;
    PalletSizeName: string;
    BaseCode: string;
    BaseName: string;
    VolumeL: number;
    GrossWeightKG: number;
    NetWeightKG: number;
    Density: number;
    BestBefore: number;
    ShelfLifePeriodInDays: number;
    SDS: string;
    UsageAreaCode: string;
    UsageAreaName: string;
    TintingSystemCode: string;
    TintingSystemName: string;
    SimpleTintBaseCode: string;
    SimpleTintBaseName: string;
    TareWeight: number;
    ManufacturerName: string;
    ManufacturerCode: string;
    MaterialClass1Name: string;
    MaterialClass1Code: string;
    MaterialClass2Name: string;
    MaterialClass2Code: string;
    MaterialClass3Name: string;
    MaterialClass3Code: string;
    MakeBuyTypeName: string;
    MakeBuyTypeCode: string;
    PhysicalStateName: string;
    PhysicalStateCode: string;
    MediumCode: string;
    MediumName: string;
    FormulaCode: string;
    FormulaName: string;
    CustomsCodeCode: string;
    CustomsCodeName: string;
    AmountOnLayer: number;
    FormulationClassification1Code: string;
    FormulationClassification1Name: string;
    FormulationClassification2Code: string;
    FormulationClassification2Name: string;
    FormulationClassification3Code: string;
    FormulationClassification3Name: string;
    AXProductDimensionGroupCode: string;
    AXProductDimensionGroupName: string;
    AXProductSubtypeCode: string;
    AXProductSubtypeName: string;
    AXProductTypeCode: string;
    AXProductTypeName: string;
    Core1Code: string;
    Core1Name: string;
    Core2Code: string;
    Core2Name: string;
    Core3Code: string;
    Core3Name: string;
    Core4Code: string;
    Core4Name: string;
    DFLClassCode: string;
    DFLClassName: string;
    EnterDateTime: Date;
    EnterUserName: string;
    ExcludeFromReportingCode: string;
    ExcludeFromReportingName: string;
    FISalesSegmentCode: string;
    FISalesSegmentName: string;
    FIToimavarMainCode: string;
    FIToimavarMainName: string;
    FIToimavarSubCode: string;
    FIToimavarSubName: string;
    FINBonusBaseCode: string;
    FINBonusBaseName: string;
    ItemStatusCode: string;
    ItemStatusName: string;
    LastChgDateTime: Date;
    LastChgUserName: string;
    LegacyStatus: number;
    PIMCode: string;
    PIMName: string;
    SVEFFClassCode: string;
    SVEFFClassName: string;
    EANCode: string;
    PreliminaryCode: string;
    ECRBrandCode: string;
    ECRBrandName: string;
    ECRGroupCode: string;
    ECRGroupName: string;
    ECRSubGroupCode: string;
    ECRSubGroupName: string;
    VTYSubClass2Code: string;
    VTYSubClass2Name: string;
    VTYSubClass1Code: string;
    VTYSubClass1Name: string;
    VTYClassCode: string;
    VTYClassName: string;
    TradeNameCreatedOn: Date;
    KeepFromFreezingName: string;
    KeepFromFreezingCode: string;
    ADRClassCode: string;
    ADRClassName: string;
    ADRSubsidiaryRiskCode: string;
    ADRSubsidiaryRiskName: string;
    ADRTransportCategoryName: string;
    ADRTransportCategoryCode: string;
    ADRPackingGroupCode: string;
    ADRPackingGroupName: string;
    ADRTunnelRestrictionCode: string;
    IATAClassCode: string;
    IATAClassName: string;
    IATASubsidiaryRiskCode: string;
    IATASubsidiaryRiskName: string;
    IMDGClassCode: string;
    IMDGClassName: string;
    IMDGSubsidiaryRiskCode: string;
    IMDGSubsidiaryRiskName: string;
    TunnelCode: string;
    TunnelName: string;
    FlashPoint: string;
    MarinePollutantCode: string;
    MarinePollutantName: string;
    MarinePollutantSubstanceCode: string;
    MarinePollutantSubstanceName: string;
    UNNumberCode: string;
    UNNumberName: string;
    ProperShippingName: string;
    ProperShippingCode: string;
    UNCompliantPackageRequiredCode: string;
    UNCompliantPackageRequiredName: string;
    EmSSpillCode: string;
    EmSSpillName: string;
    EmSFireCode: string;
    EmSFireName: string;
    EnvironmentalHazardExemptCode: string;
    EnvironmentalHazardExemptName: string;
    ViscousFlammableExemptCode: string;
    ViscousFlammableExemptName: string;
    FlammabilityClassCode: string;
    FlammabilityClassName: string;
    IATAPackingInstructionNumberCode: string;
    IATAPackingInstructionNumberName: string;
    NoveltyCode: string;
    NoveltyName: string;
    CoreSummaryCode: string;
    CoreSummaryName: string;
    GrossDepth: number;
    GrossHeight: number;
    GrossWidth: number;
    MLFClassCode: string;
    MLFClassName: string;
    PortfolioOwnerCode: string;
    PortfolioOwnerName: string;
    PortfolioOwnerID: number;
    ProductOwnerCode: string;
    ProductOwnerName: string;
    ProductOwnerID: number;
    _SharedItemLifeCycleAxStatus: string;
    ReleasedItemLifeCycleAxStatus: string;
    SourceOriginCountry: string;
    SourceOriginCountryName: string;
    CanCapacityCode: string;
    CanCapacityName: string;
    FormulaResponsibleName: string;
    FormulaResponsibleCode: string;
    FormulaResponsibleAXEmployeeId: string;
    FormulaResponsibleFirstName: string;
    MainTargetGroupSegment: string;
    VendorCode: string;
    VendorName: string;
    ChemicalName: string;
    BrandRU: string;
    ProductGroupRU: string;
    SKUClassification1Budget: string;
    BrandBudget: string;
    BrandSegment: string;
    SecurityType: string;
    UnitSequenzeGroupID: string;
    PackSizeCategoryID: string;
    FilterCode3: string;
    PackingGroupID: string;
    PackingGroupName: string;
    TrackingDimensionGroup: string;
    VisibleInWebShop: string;
    LogicalName: string;
}
