import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Warehouse } from 'src/app/shared/models/warehouse';

@Component({
  selector: 'ntw-select-warehouse-dialog',
  templateUrl: './select-warehouse-dialog.component.html',
  styleUrls: ['./select-warehouse-dialog.component.scss']
})
export class SelectWarehouseDialogComponent implements OnInit, OnDestroy {
  @Input() public warehouses: Warehouse[];
  @Output() public warehouseSelected: EventEmitter<Warehouse | undefined> = new EventEmitter<Warehouse | undefined>();

  public selectedWarehouse: Warehouse = null;

  public loading = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'no-scroll');
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }

  selectedChanged(warehouse: Warehouse): void {
    if (warehouse && warehouse.warehouseName && warehouse.bkWarehouseKey) {
      this.selectedWarehouse = warehouse;
    }
    else {
      this.selectedWarehouse = null;
    }
  }

  close(): void {
    this.warehouseSelected.emit(this.selectedWarehouse);
  }
}
