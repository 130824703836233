import { CosmosCustomer } from "../customer";
import { InvoiceHeader } from "../invoices/invoice-header";

export class PrintInvoiceRequest {
    companyId: string;
    documentDate: string;
    documentNum: string;
    custAccount: string;
    emailTo: string;

    constructor(invoice: InvoiceHeader, email: string, customer: CosmosCustomer) {
        const documentDate = invoice.InvoiceDate;
        this.companyId = customer.TikkurilaCompanyRegisterCode;
        this.documentDate = `${documentDate.getFullYear()}-${String(documentDate.getMonth() + 1).padStart(2, '0')}-${String(documentDate.getDate()).padStart(2, '0')}`;
        this.documentNum = invoice.InvoiceNumber;
        this.custAccount = invoice.bkInvoiceAccountKey;
        this.emailTo = email;
    }
}

export interface PrintInvoiceResponse {
    errorMsg: string;
    resultRecId: number;
    resultStr: string;
    resultXML: string;
    isOk: boolean;
}
