import { PortalModule } from '@angular/cdk/portal';
import {
  CommonModule,
  DatePipe
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule,
  ButtonsModule
} from '@progress/kendo-angular-buttons';
import {
  ChartModule,
  ChartsModule
} from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { AppRoutingModule } from '../app-routing.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { CustomerServiceDialogComponent } from './components/customer-service-dialog/customer-service-dialog.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DownloadLinkComponent } from './components/download-link';
import { DropDownListFilterComponent } from './components/dropDownListFilterComponent';
import { GridTemplateComponent } from './components/grid-template/grid-template.component';
import { ListItemComponent } from './components/item-selection/list-item';
import { ProductListItemComponent } from './components/item-selection/product-list-item';
import { SelectItemComponent } from './components/item-selection/select-item.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { LoadingDropdownlistComponent } from './components/loading-dropdownlist/loading-dropdownlist/loading-dropdownlist.component';
import { LoadingPanelComponent } from './components/loading-panel';
import { MaintenanceDialogComponent } from './components/maintenance-dialog/maintenance-dialog/maintenance-dialog.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductDetailDialogComponent } from './components/product-detail-dialog/product-detail-dialog.component';
import { SelectWarehouseDialogComponent } from './components/select-warehouse-dialog/select-warehouse-dialog/select-warehouse-dialog.component';
import { TitleValueComponent } from './components/title-value.component';
import { TextBoxExtensionDirective } from './directives/kendo-textbox.directive';
import { ContextGuardService } from './security/guards/context-guard.service';
import { InterceptorProviders } from './security/interceptors/interceptor-providers';
import { AddressService } from './services/address/address.service';
import { IAddressService } from './services/address/address.service.interface';
import { ColorshadeService } from './services/colorshade/colorshade.service';
import { IColorshadeService } from './services/colorshade/colorshade.service.interface';
import { CompanyAdminConfigurationService } from './services/company-admin-configuration/company-admin-configuration.service';
import { ICompanyAdminConfigurationService } from './services/company-admin-configuration/company.admin.configuration.service.interface';
import { ContactService } from './services/contact/contact.service';
import { IContactService } from './services/contact/contact.service.interface';
import { CustomerSettingsService } from './services/customer-settings/customer-settings.service';
import { ICustomerSettingsService } from './services/customer-settings/customer-settings.service.interface';
import { CustomerService } from './services/customer/customer.service';
import { ICustomerService } from './services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from './services/filter-sort-description.service';
import { KendoAlertService } from './services/kendo-alerts.service';
import { KendoTranslationService } from './services/kendo-translation.service';
import { OrderCartService } from './services/order-cart.service';
import { PrintService } from './services/print/print.service';
import { IPrintService } from './services/print/print.service.interface';
import { StorageService } from './services/storage/storage-service';
import { UserSettingsService } from './services/user/user-settings.service';
import { IUserSettingsService } from './services/user/user-settings.service.interface';
import { WarehouseService } from './services/warehouse/warehouse.service';
import { CompanyAdminConfigurationStore } from './stores/company-admin-configuration.store';
import { CurrentUserContactStore } from './stores/current-user-contact.store';
import { CustomerSettingsStore } from './stores/customer-settings.store';
import { UserSettingsStore } from './stores/user-settings.store';

// // Load all required data for the bg locale
// import '@progress/kendo-angular-intl/locales/pl/all';


@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CustomerServiceDialogComponent,
    DashboardCardComponent,
    DownloadLinkComponent,
    DropDownListFilterComponent,
    GridTemplateComponent,
    ListItemComponent,
    LoadingButtonComponent,
    LoadingDropdownlistComponent,
    LoadingPanelComponent,
    MaintenanceDialogComponent,
    PdfViewerDialogComponent,
    ProductCardComponent,
    ProductDetailDialogComponent,
    ProductListItemComponent,
    SelectItemComponent,
    SelectWarehouseDialogComponent,
    TextBoxExtensionDirective,
    TitleValueComponent,
    ProductCardComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    ButtonsModule,
    ButtonsModule,
    ChartModule,
    ChartsModule,
    CommonModule,
    DateInputsModule,
    DialogsModule,
    DropDownsModule,
    FormsModule,
    GridModule,
    HttpClientModule,
    IconsModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ListViewModule,
    NavigationModule,
    NotificationModule,
    PagerModule,
    PopupModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
    TreeListModule,
    TreeViewModule,
  ],
  exports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    ButtonsModule,
    ButtonsModule,
    ChartModule,
    ChartsModule,
    ConfirmDialogComponent,
    CustomerServiceDialogComponent,
    DateInputsModule,
    DashboardCardComponent,
    DialogsModule,
    DownloadLinkComponent,
    DropDownListFilterComponent,
    DropDownsModule,
    FormsModule,
    GridModule,
    GridTemplateComponent,
    HttpClientModule,
    IconsModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ListViewModule,
    LoadingButtonComponent,
    LoadingDropdownlistComponent,
    LoadingPanelComponent,
    MaintenanceDialogComponent,
    NavigationModule,
    NotificationModule,
    PagerModule,
    PdfViewerDialogComponent,
    PopupModule,
    PortalModule,
    ProductCardComponent,
    ProductDetailDialogComponent,
    ProductListItemComponent,
    ReactiveFormsModule,
    RouterModule,
    SelectItemComponent,
    SelectWarehouseDialogComponent,
    TextBoxExtensionDirective,
    TitleValueComponent,
    TooltipModule,
    TranslateModule,
    TreeListModule,
    TreeViewModule,
  ],
  providers: [
    { provide: IAddressService, useClass: AddressService },
    { provide: IColorshadeService, useClass: ColorshadeService },
    { provide: ICompanyAdminConfigurationService, useClass: CompanyAdminConfigurationService },
    { provide: IContactService, useClass: ContactService },
    { provide: ICustomerService, useClass: CustomerService },
    { provide: ICustomerSettingsService, useClass: CustomerSettingsService },
    { provide: IPrintService, useClass: PrintService },
    { provide: IUserSettingsService, useClass: UserSettingsService },
    { provide: MessageService, useExisting: KendoTranslationService },
    CompanyAdminConfigurationStore,
    ContextGuardService,
    CurrentUserContactStore,
    CustomerSettingsStore,
    DatePipe,
    FilterAndSortDescriptionService,
    InterceptorProviders,
    KendoAlertService,
    OrderCartService,
    StorageService,
    UserSettingsStore,
    WarehouseService
  ],
})
export class SharedModule { }


