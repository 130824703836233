import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerTaskResponse } from 'src/app/shared/models/customer-task-response';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { CustomerTask } from 'src/app/shared/models/task';
import { environment } from 'src/environments/environment';
import { ITaskService } from './task.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TaskService implements ITaskService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  getTaskById(taskId: string, accountId: string): Observable<CustomerTask> {
    const selector = 'tikk_id,tikk_subject,tikk_due,tikk_durationminutes,tikk_description,tikk_createdon,tikk_modifiedon,'
      + 'tikk_prioritycode,tikk_priorityname,'
      + 'tikk_ownerid,tikk_ownername,'
      + 'tikk_statecode,tikk_statename,'
      + 'tikk_statuscode,tikk_statusname,'
      + 'tikk_regardingid,tikk_regardingname';
    const url = this.baseUrl + `customertasks`
      + `?$select=${selector}`
      + `&$filter=tikk_id eq ${taskId} and tikk_regardingid eq ${accountId}`;

    return this.httpClient.get<ODataResponse<CustomerTask[]>>(url).pipe(
      map(response => {
        if (response && response.value && response.value[0]) {
          return response.value[0];
        }
        return null;
      }));
  }

  getTasksByAccountId(accountId: string): Observable<CustomerTask[]> {
    const taskSelector = 'tikk_id,tikk_subject,tikk_due,tikk_durationminutes,'
      + 'tikk_prioritycode,tikk_priorityname,'
      + 'tikk_ownerid,tikk_ownername,'
      + 'tikk_statecode,tikk_statename,'
      + 'tikk_statuscode,tikk_statusname,'
      + 'tikk_regardingid';
    const url = this.baseUrl + `customertasks`
      + `?$select=${taskSelector}`
      + `&$filter=tikk_regardingid eq ${accountId}`;

    return this.httpClient.get<ODataResponse<CustomerTask[]>>(url).pipe(map(response => { return response.value; }));
  }

  sendCustomerResponse(customerResponse: CustomerTaskResponse): Observable<unknown> {
    const url = this.baseUrl + `customertasks/CustomerResponse`;
    return this.httpClient.patch(url, customerResponse);
  }
}
