import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { Selectors } from 'src/app/shared/constants/selectors';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { Product } from 'src/app/shared/models/products/product';

@Injectable()
export abstract class IProductService {
    baseUrl: string;

    abstract getProductByAxCode(id: string): Observable<Product>;
    abstract getProductsWithFilters(gridState: State, selector: Selectors, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<Product[]>>;
}
