import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IAccountService } from 'src/app/account/services/account.service.interface';
import { SecurityService } from '../security.service';

@Injectable({
  providedIn: 'root'
})
export class ContextGuardService {
  constructor(
    private accountService: IAccountService,
    private securityService: SecurityService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      const accountId = route.params['accountid'];
      if (!accountId) {
        this.router.navigate(['select-account']);
      }

      const currentAccount = this.securityService.getCurrentAccountObject();

      if (currentAccount && currentAccount.accountid == accountId) {
        obs.next(true);
      } else {
        this.accountService.getCrmAccountById(accountId).subscribe({
          next: foundAccount => {
            if (foundAccount) {
              this.securityService.setCurrentAccount(foundAccount);
              obs.next(true);
            } else {
              this.router.navigate(['select-account']);
            }
          },
          error: error => {
            console.error(error);
            obs.next(false);
          }
        });
      }
    })
  }
}
