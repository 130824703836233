import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CosmosCustomer } from "../../models/customer";
import { PortalOrderProduct } from "../../models/order-creation/portal-order-product";
import { ProductWithInventoryAndQuantity } from "../../models/order-creation/product-with-quantity";
import { PriceListResponse } from "../../models/pricelists/pricelist-response";

@Injectable({
    providedIn: 'root'
})

export abstract class IPricelistService {
    public abstract getPriceForSingleProduct(product: PortalOrderProduct, customer: CosmosCustomer): Observable<PriceListResponse>;
    public abstract getPriceForMultipleProducts(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer): Observable<PriceListResponse>;
}
