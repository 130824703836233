import { SVGIcon } from "@progress/kendo-svg-icons"

const listViewIcon: SVGIcon = {
    name: "list-view",
    content: `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
  <rect x="10" y="20" width="16" height="16" rx="4"/>
  <rect x="10" y="45" width="16" height="16" rx="4"/>
  <rect x="10" y="70" width="16" height="16" rx="4"/>
  <rect x="30" y="20" width="60" height="16" rx="4"/>
  <rect x="30" y="45" width="60" height="16" rx="4"/>
  <rect x="30" y="70" width="60" height="16" rx="4"/>
</svg>`,
    viewBox: "0 0 100 100"
};

const cardViewIcon: SVGIcon = {
    name: "card-view",
    content: `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
  <rect x="8" y="8" width="38" height="38" rx="4"/>
  <rect x="52" y="8" width="38" height="38" rx="4"/>
  <rect x="8" y="52" width="38" height="38" rx="4"/>
  <rect x="52" y="52" width="38" height="38" rx="4"/>
</svg>`,
    viewBox: "0 0 100 100"
};

export { listViewIcon, cardViewIcon };
