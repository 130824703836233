import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CrmContact } from "../../models/contact";
import { ODataResponse } from "../../models/odata-response";
import { IContactService } from "./contact.service.interface";

@Injectable({
    providedIn: 'root'
})

export class ContactService implements IContactService {
    baseUrl = "";
    contactSelector = "contactid,emailaddress1";

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl + "contacts";
    }

    public getCurrentUsersContact(): Observable<CrmContact> {
        const url = this.baseUrl + "/my" + `?$select=${this.contactSelector}`;
        return this.httpClient.get<ODataResponse<CrmContact[]>>(url).pipe(map(x => x.value[0]));
    }
}
