import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { PortalOrderProduct } from 'src/app/shared/models/order-creation/portal-order-product';
import { InventoryOffline, InventoryOnline, InventoryOnlineRequest, InventoryOnlineResponse, InventoryOnlineStatus } from 'src/app/shared/models/products/inventory';
import { Product } from 'src/app/shared/models/products/product';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { IInventoryService } from './inventory.service.interface';

@Injectable({
    providedIn: 'root'
})

export class InventoryService implements IInventoryService {
    baseUrl: string;
    chunkSize = 25;

    selector = "bkProductCoverageKey, bkProductKey, PhysicalInvent, ModificationDate";

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl;
    }

    /**
      * Gets the inventory for a warehouse by products in offline mode.
      * @param bkWarehouseKey The key of the warehouse to get the inventory for.
      * @param products The products to get the inventory for.
      * @returns An observable that emits an array of InventoryOffline objects each time the inventory is retrieved for a product.
      */
    getInventoryForWarehouseByProductsOffline(bkWarehouseKey: string, products: Product[]): Observable<InventoryOffline[]> {
        // Split the products into chunks of 10
        const chunks = Utils.chunkArray([...products], this.chunkSize);

        // Create an observable from the chunks array
        return from(chunks).pipe(
            mergeMap(chunk => {
                return this.getInventoryForWarehouseByProductsOfflineChunk(bkWarehouseKey, chunk);
            }),
            map(response => {
                return response.value;
            })
        );
    }

    getInventoryForWarehouseByProductsOfflineChunk(bkWarehouseKey: string, products: Product[]): Observable<ODataResponse<InventoryOffline[]>> {
        // Construct the URL for the OData request
        const url = this.baseUrl + `inventories?$select=${this.selector}`
                    + this.getFilter(bkWarehouseKey, products)
                    + `&$top=${products.length}`;

        // Return an observable that emits an array of InventoryOffline objects
        return this.httpClient.get<ODataResponse<InventoryOffline[]>>(url);
    }

    getFilter(bkWarehouseKey: string, products: Product[]): string {
        const invKeys = this.getBkProductCoverageKeys(bkWarehouseKey, products);
        invKeys.forEach((key, idx) => {
            invKeys[idx] = `'${key}'`;
        });

        return `&$filter=bkProductCoverageKey in (${invKeys.join(', ')})`;
    }

    getBkProductCoverageKeys(bkWarehouseKey: string, products: Product[]): string[] {
        const keys: string[] = [];
        products.forEach(product => {
            keys.push(`${product.AXCode}_${product.DataAreaID}_${bkWarehouseKey}`);
        });
        return keys;
    }

    getInventoryForWarehouseByProductsOnline(bkCompanyKey: string, bkWarehouseKey: string, products: PortalOrderProduct[]): Observable<InventoryOnline[]> {
        const chunks = Utils.chunkArray([...products], 50);
        const observables: Observable<InventoryOnlineResponse>[] = [];
        chunks.forEach(chunk => {
            observables.push(this.getInventoryForWarehouseByProductsOnlineChunk(bkCompanyKey, bkWarehouseKey, chunk));
        });

        return new Observable<InventoryOnline[]>(observer => {
            let inventories: InventoryOnline[] = [];
            let count = 0;
            observables.forEach(observable => {
                observable.subscribe(response => {
                    inventories = inventories.concat(response.products.filter(inventory => inventory && inventory.itemId));
                    count++;
                    if (count === observables.length) {
                        observer.next(inventories);
                        observer.complete();
                    }
                });
            });
        });
    }

    private getInventoryForWarehouseByProductsOnlineChunk(bkCompanyKey: string, bkWarehouseKey: string, products: PortalOrderProduct[]): Observable<InventoryOnlineResponse> {
        const url = this.baseUrl + 'pricelists/productprice/itemavailable';
        const request = new InventoryOnlineRequest(bkCompanyKey, bkWarehouseKey, products);

        return this.httpClient.post<InventoryOnlineResponse>(url, request).pipe(map(response => {
            const now = new Date();
            response.products = response.products.map(inventory => {
                const product = products.find(p => p.axCode === inventory.itemId);
                if (product !== undefined) {
                    inventory.axCode = product.axCode;
                    inventory.status = InventoryOnlineStatus.Loaded;
                    inventory.dateUpdated = now;
                    return inventory;
                }
                return undefined;
            });
            return response;
        }));
    }
}
