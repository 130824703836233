import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { PortalOrderService } from '../portal-order/services/portal-orders.service';
import { PricelistService } from '../shared/services/pricelist/pricelist.service';
import { IPricelistService } from '../shared/services/pricelist/pricelist.service.interface';
import { SharedModule } from '../shared/shared.module';
import { OrderProcessComponent } from './order-process.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { ReviewCartComponent } from './review-cart/review-cart.component';
import { SelectDetailsComponent } from './select-details/select-details.component';
import { SelectProductsComponent } from './select-products/select-products.component';
import { SKUFilterBreadCrumbsComponent } from './select-products/sku-filter/sku-filter/sku-filter-breadcrumbs.component';
import { SkuFilterComponent } from './select-products/sku-filter/sku-filter/sku-filter.component';
import { SelectWarehouseComponent } from './select-warehouse/select-warehouse.component';
import { OrderProcessService } from './services/order-process.service';
import { IOrderProcessService } from './services/order.process.service.interface';


@NgModule({
  declarations: [
    OrderProcessComponent,
    SelectWarehouseComponent,
    SelectProductsComponent,
    SkuFilterComponent,
    SKUFilterBreadCrumbsComponent,
    ReviewCartComponent,
    SelectDetailsComponent,
    OrderSummaryComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    { provide: IOrderProcessService, useClass: OrderProcessService },
    PortalOrderService,
    { provide: IPricelistService, useClass: PricelistService }
  ]
})
export class OrderProcessModule { }
