import { PortalOrderProduct } from "../order-creation/portal-order-product";

export class InventoryOffline {
    LogicalName: string;
    bkWarehouseKey: string;
    bkProductKey: string;
    bkProductCoverageKey: string;
    bkCompanyKey: string;
    bkSiteKey: string;
    skDateKey: number;
    PhysicalInvent: number;
    ModificationDate: Date;
}

export class InventoryOnline {
    itemId: string;
    availQtyInSalesUnit: number;
    salesUnit: string;
    availQtyInInventUnit: number;
    inventUnit: string;

    // values mapped locally
    axCode: string;
    status: InventoryOnlineStatus;
    dateUpdated: Date;
}

export enum InventoryOnlineStatus {
    Loading,
    NotAvailable,
    Loaded,
}

export class InventoryOnlineResponse {
    products: InventoryOnline[];
}

export class InventoryOnlineRequest {
    CompanyId: string;
    Warehouse: string;
    Request: InventoryOnlineRequestReq;

    constructor(companyId: string, warehouse: string, products: PortalOrderProduct[]) {
        this.CompanyId = companyId;
        this.Warehouse = warehouse;
        this.Request = new InventoryOnlineRequestReq(products);
    }
}

class InventoryOnlineRequestReq {
    Params: InventoryOnlineRequestParams

    constructor(products: PortalOrderProduct[]) {
        this.Params = new InventoryOnlineRequestParams(products);
    }
}

class InventoryOnlineRequestParams {
    Product: InventoryOnlineRequestParamsProduct[] = [];

    constructor(products: PortalOrderProduct[]) {
        products.forEach(portalProduct => {
            const paramProduct = new InventoryOnlineRequestParamsProduct(portalProduct);
            this.Product.push(paramProduct);
        });
    }
}

class InventoryOnlineRequestParamsProduct {
    Id: string;

    constructor(product: PortalOrderProduct) {
        this.Id = product.axCode;
    }
}
